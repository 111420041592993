<template>
    <div class="user">
        <el-card class="table-inner">
            <div class="handle-0">
                <el-button type="primary" size="small" @click="handleAdd">创建机构</el-button>
            </div>
            <el-table
                :data="tableData"
                border
                style="width: 100%"
                class="glo-table-switch"
                v-loading="loading"
                element-loading-text="加载中"
                row-key="id"
                default-expand-all
                :tree-props="{children: 'children'}"
            >
                <el-table-column prop="orgName" label="机构名称" min-width="150"></el-table-column>
                <el-table-column prop="sortIndex" label="排序" min-width="110"></el-table-column>
                <!-- <el-table-column label="负责人" min-width="100">
                    <template  slot-scope="scope">
                        <span>{{scope.row.offMaster || '暂未设置'}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="联系电话" min-width="150">
                    <template  slot-scope="scope">
                        <span>{{scope.row.offPhone || '暂未设置'}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="机构状态" min-width="100">
                    <template  slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.useable ==='0'">正常</el-tag>
                        <el-tag type="danger" size="mini" v-else>停用</el-tag>
                    </template>
                </el-table-column> -->
                <el-table-column label="操作" min-width="160">
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.row)" type="primary" size="mini">编辑</el-button>
                        <el-button @click="handleDelete(scope.row.id)" type="danger" size="mini">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog
            :title="dialogTitle=== 1 ? '创建机构' : '编辑机构' "
            :visible.sync="dialog"
            width="26%"
            @closed="dialogClose"
            :close-on-click-modal="false"
        >
            <el-form ref="form" :rules="rules" :model="form" label-width="80px">
                <el-form-item label="机构名称" prop="orgName">
                    <el-input v-model="form.orgName" maxlength="16" size="small" placeholder="请填写机构名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="上级机构" prop="parentId" v-if="form.parentId !== '0'">
                    <el-cascader
                        v-model="form.parentId"
                        :options="treeData"
                        :props="{ checkStrictly: true,emitPath: false}"
                        size="mini"
                        clearable
                    ></el-cascader>
                </el-form-item>
                <!-- <el-form-item label="负责人" prop="offMaster">
                    <el-input v-model="form.offMaster" size="small" placeholder="请填写负责人" clearable></el-input>
                </el-form-item>
                <el-form-item label="联系电话">
                    <el-input v-model="form.offPhone" size="small" placeholder="请填写联系电话" clearable></el-input>
                </el-form-item>
                <el-form-item label="电子邮箱">
                    <el-input v-model="form.offEmail" size="small" placeholder="请填写电子邮箱" clearable></el-input>
                </el-form-item> -->
                <el-form-item label="显示排序">
                    <el-input v-model="form.sortIndex" maxlength="3" size="small" placeholder="请填写显示排序" clearable></el-input>
                </el-form-item>
                <!-- <el-form-item label="机构状态">
                    <el-switch v-model="form.useable" inactive-color="#ff4949" active-value="0" inactive-value="1"></el-switch>
                </el-form-item> -->
            </el-form>
            <span slot="footer">
                <el-button type="primary" @click="submit" size="small">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data(){
        return{
            loading:true,
            tableData: [],
            form:{
                orgName:'',
                parentId:'',
                sortIndex:'',
                // offMaster:'',
                // offPhone:'',
                // offEmail:'',
                // useable:'0'
            },
            rules:{
                parentId: [
                    { required: true, message: '请选择上级机构', trigger: 'change' }
                ],
                orgName: [
                    { required: true, message: '请填写机构名称', trigger: 'blur' },
                    { min: 2, max: 16, message: '长度在 2 到 16 个字符', trigger: 'blur' }
                ],
            },
            dialogTitle:1,
            dialog:false,
            treeData:[]
        }
    },
    methods:{
        // 表格开关切换状态
        // changeSatus(row){
        //     console.log(row);
        //     let text = row.status === 1? '停用': '启用';
        //     this.$confirm(`确定要${text}用户吗?`, '提示', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //         type: 'warning'
        //     }).then(() => {
        //         this.tableData.forEach( e => {
        //             if(e.id === row.id){
        //                 row.status === 1 ?
        //                 e.status = 0 : e.status = 1
        //             }
        //         })
        //         this.$message({
        //             type: 'success',
        //             message: '操作成功!',
        //             duration:1500
        //         })
        //     }).catch(() => {})
        // },
        // 增加用户
        handleAdd(){
            this.dialogTitle = 1;
            this.dialog = true;
        },
        // 编辑用户信息
        handleEdit(row){
            this.dialogTitle = 2;
            this.dialog = true;
            this.form = {...row}
        },
        // 关闭对话框
        dialogClose(){
            this.form = {
                orgName:'',
                parentId:'',
                sortIndex:'',
                // offMaster:'',
                // offPhone:'',
                // offEmail:'',
                // useable:'0'
            }
            setTimeout(()=>{
                this.$refs['form'].clearValidate();
            },10)
        },
        // 提交保存
        submit(){
            let data = {...this.form};
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if(this.dialogTitle === 1){
                        // 新增
                        delete data.id;
                        this.$api.orgSave(data).then( d=> {
                            this.$message({
                                type: 'success',
                                message: '创建成功!'
                            });
                            this.dialog = false;
                            this.loading = true;
                            setTimeout(()=>{
                                this.loadData();
                            },500)
                        })
                    }else if(this.dialogTitle === 2){
                        // 修改
                        this.$api.orgUpdate(data).then( d=> {
                            this.$message({
                                type: 'success',
                                message: '更新成功!'
                            });
                            this.dialog = false;
                            this.loading = true;
                            setTimeout(()=>{
                                this.loadData();
                            },500)
                        })
                    }

                } else {
                    return false;
                }
            });
        },
        // 删除部门
        handleDelete(id){
            console.log(id);
            this.$confirm('确定要删除该机构吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.orgDel(id).then( d =>{
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                        duration:1500
                    });
                    this.loading = true;
                    setTimeout(()=>{
                        this.loadData();
                    },500)
                })
            }).catch(() => {})
        },
        loadData(){
            return new Promise( (resolve,rejuect) => {
                this.$api.orgTree().then( d => {
                    if(d?.length){
                        this.tableData = d;
                        this.treeData = this.filterJson(d);
                    }else{
                        this.tableData = [];
                    }
                    this.loading = false;
                    resolve();
                })
            })
        },
        // 遍历格式化树结构
        filterJson(arr) {
            const res = [];
            arr.forEach(v => {
                let tmp = { ...v };
                    tmp.label = v.orgName;
                    tmp.value = v.id;
                    if (tmp.children.length>0) {
                        tmp.children = this.filterJson(tmp.children)
                    }else{
                        tmp.children = undefined;   // 修复-用户管理选择所属部门时部门级联菜单最后一级暂无数据问题
                    }
                    res.push(tmp)
            })
            return res;
        },
    },
    mounted(){
        this.loadData();
    }
}
</script>

<style lang="scss" scoped>
.user{
    width: calc(100% - 10px);
    height: 100%;
    overflow-y: auto;
    .condition{
        margin-bottom: 10px;
        .el-col{
            display: inline-block;
            margin: 5px 0;
        }
    }
    .table-inner{
        .handle-0{
            margin-bottom: 10px;
        }
        .pages-0{
            margin-top: 10px;
            text-align: right;
        }
    }
}
</style>
