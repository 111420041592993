<template>
    <div class="log">
        <el-card class="condition">
            <el-row :gutter="20">
                <el-col :xs="16" :sm="6" :lg="5" :xl="4"><el-input placeholder="根据操作者姓名查询" v-model="filtrate.userName" size="small" clearable></el-input></el-col>
                <el-col :xs="18" :sm="12" :lg="9" :xl="6">
                    <el-date-picker
                        v-model="filtrate.time"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="操作开始时间"
                        end-placeholder="操作结束时间"
                        size="small"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                    >
                    </el-date-picker>
                </el-col>
                <el-col :xs="12" :sm="8" :lg="5" :xl="4">
                    <el-button type="success" size="small" round @click="search"><i class="el-icon-search glo-icon-mgr2"></i>搜索</el-button>
                    <el-button type="warning" size="small" round @click="reset"><i class="el-icon-refresh glo-icon-mgr2"></i>重置</el-button>
                </el-col>
            </el-row>
        </el-card>
        <el-card class="table-inner">
            <el-table
                :data="tableData"
                border
                style="width: 100%"
                class="glo-table-switch"
                v-loading="loading"
                element-loading-text="加载中"
            >
                <el-table-column prop="id" label="编号" min-width="80"></el-table-column>
                <el-table-column prop="title" label="接口名称" min-width="100"></el-table-column>
                <el-table-column prop="remoteAddr" label="操作者IP" min-width="110"></el-table-column>
                <el-table-column prop="userName" label="操作者" min-width="110"></el-table-column>
                <el-table-column prop="createDate" label="操作时间" min-width="150"></el-table-column>
                <el-table-column label="操作结果" min-width="100">
                    <template  slot-scope="scope">
                        <el-tag size="small" type="success" v-if="scope.row.type==='1'">成功</el-tag>
                        <el-tag size="small" type="danger" v-else>失败</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="100">
                    <template slot-scope="scope">
                        <el-button @click="handleDetail(scope.row)" type="success" size="mini">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pages-0">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pages.current"
                    :page-sizes="pages.option"
                    :page-size="pages.size"
                    layout="total, sizes, prev, pager, next, jumper" :total="pages.total"
                ></el-pagination>
            </div>
        </el-card>

        <el-dialog
            title="操作日志详情"
            :visible.sync="dialog"
            width="60%"
            top="8vh"
            :close-on-click-modal="false"
        >
            <el-form label-width="100px">
                <el-form-item label="请求地址" prop="role">
                    {{form.requestUri}}
                </el-form-item>
                <el-form-item label="请求方式" prop="role">
                    {{form.method}}
                </el-form-item>
                <el-form-item label="请求参数" prop="role">
                    <div class="param-code-border">{{form.params || '无'}}</div>
                </el-form-item>
                <el-form-item label="响应状态" prop="role">
                    <el-tag size="small" type="success" v-if="form.type==='1'">成功</el-tag>
                    <el-tag size="small" type="danger" v-else>失败</el-tag>
                </el-form-item>
                <el-form-item label="操作者" prop="role">
                    {{form.userName}}
                </el-form-item>
                <el-form-item label="操作者IP" prop="role">
                    {{form.remoteAddr}}
                </el-form-item>
                <el-form-item label="操作时间" prop="role">
                    {{form.createDate}}
                </el-form-item>
            </el-form>
            <span slot="footer">
                <el-button type="primary" @click="dialog=false" size="small">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data(){
        return{
            loading:true,
            tableData: [],
            pages:{
                current:1,
                option:[8, 10, 12,20],
                size:10,
                total:0
            },
            form:{},
            currentPage:1,
            filtrate:{
                userName:'',
                time:[],
                startTime:'',
                endTime:''
            },
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            dialog:false
        }
    },
    methods:{
        handleSizeChange(size) {
            this.pages.current = 1;
            this.pages.size = size;
            this.loading = true;
            this.loadData();
        },
        handleCurrentChange(page) {
            this.loading = true;
            setTimeout( ()=>{
                this.pages.current = page;
                this.loadData();
            },100)
        },
        // 筛选-检索
        search(){
            if(this.filtrate?.time?.length){
                this.filtrate.startTime = this.filtrate.time[0];
                this.filtrate.endTime = this.filtrate.time[1];
            }
            // 组件清除按钮无法清除传值数据
            if(!this.filtrate.time){
                this.filtrate.startTime = '';
                this.filtrate.endTime = '';
            }
            this.loading = true;
            this.pages.current = 1;
            setTimeout( ()=>{
                this.loadData();
            },500)
        },
        // 筛选-重置
        reset(){
            this.filtrate={
                userName:'',
                time:[],
                startTime:'',
                endTime:''
            }
            this.search();
        },
        handleDetail(row){
            console.log(row);
            this.form = {...row};
            this.dialog = true
        },
        loadData(){
            let data = {
                userName:this.filtrate.userName,
                startTime:this.filtrate.startTime,
                endTime:this.filtrate.endTime
            }
            return new Promise( (resolve) => {
                this.$api.logPage(this.pages.current,this.pages.size,data).then( d => {
                    if(d?.records?.length){
                        this.tableData = d.records;
                        this.pages.total = +d.total;
                    }else{
                        this.tableData = [];
                        this.pages.total = 0;
                    }
                    this.loading = false;
                    resolve();
                })
            })
        }
    },
    mounted(){
        this.loadData();
    }
}
</script>

<style lang="scss" scoped>
.log{
    width: calc(100% - 10px);
    height: 100%;
    overflow-y: auto;
    .condition{
        margin-bottom: 10px;
        .el-col{
            display: inline-block;
            margin: 5px 0;
        }
    }
    .table-inner{
        .handle-0{
            margin-bottom: 10px;
        }
        .pages-0{
            margin-top: 10px;
            text-align: right;
        }
    }
    .param-code-border{
        display: block;
        padding: 9.5px;
        margin: 0 0 10px;
        font-size: 13px;
        line-height: 1.42857143;
        color: #333;
        word-break: break-all;
        word-wrap: break-word;
        background-color: #f5f5f5;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
}
</style>
