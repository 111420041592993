<template>
    <div class="menu">
        <el-card class="condition">
            <el-row :gutter="20">
                <el-col :span="4" :xs="16" :sm="6" :lg="5" :xl="4">
                    <el-select v-model="parentOsId" size="small" placeholder="选择单个平台查询" clearable>
                        <el-option
                        v-for="item in osOpt"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="4" :xs="10" :sm="6" :lg="5" :xl="4">
                    <el-button type="success" size="small" round @click="search"><i class="el-icon-search glo-icon-mgr2"></i>搜索</el-button>
                    <el-button type="warning" size="small" round @click="reset"><i class="el-icon-refresh glo-icon-mgr2"></i>重置</el-button>
                </el-col>
            </el-row>
        </el-card>
        <el-card class="table-inner">
            <div class="handle-0">
                <el-button type="primary" size="small" @click="handleAdd">创建菜单</el-button>
            </div>
            <el-table
                :data="tableData"
                border
                :expand-row-keys="arrayExpand"
                row-key="id"
                style="width: 100%"
                class="glo-table-switch"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                v-loading="loading"
                element-loading-text="加载中"
            >
                <el-table-column prop="name" label="菜单名称" min-width="100"></el-table-column>
                <el-table-column prop="sort" label="排序编号" min-width="110"></el-table-column>
                <el-table-column label="菜单类型" min-width="110">
                    <template  slot-scope="scope">
                        <el-tag size="small" v-if="scope.row.type=== '1'">目录</el-tag>
                        <el-tag size="small" type="success" v-if="scope.row.type=== '2'">菜单</el-tag>
                        <el-tag size="small" type="warning" v-if="scope.row.type=== '3'">按钮</el-tag>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="path" label="前端路由路径" min-width="110"></el-table-column> -->
                <el-table-column label="状态" min-width="100">
                    <template  slot-scope="scope">
                        <!-- <el-switch disabled v-model="scope.row.enabled" inactive-color="#ff4949" active-value="1" inactive-value="0"  @click.native="changeSatus(scope.row)"></el-switch> -->
                        <el-switch disabled v-model="scope.row.enabled" inactive-color="#ff4949" active-value="1" inactive-value="0"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="160">
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.row)" type="primary" size="mini">编辑</el-button>
                        <el-button @click="handleDelete(scope.row.id)" type="danger" size="mini">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <el-dialog
            :title="dialogTitle=== 1 ? '创建菜单' : '编辑菜单' "
            :visible.sync="dialog"
            width="32%"
            @closed="dialogClose"
            :close-on-click-modal="false"
        >
            <el-form ref="form" :rules="rules" :model="form" label-width="120px">
                <el-form-item label="菜单名称" prop="name">
                    <el-input v-model="form.name" maxlength="10" size="small" placeholder="请填写菜单名称"></el-input>
                </el-form-item>
                <el-form-item label="父级菜单" prop="pcode" v-if="form.pcode !== '0'">
                    <el-cascader
                        v-model="form.pcode"
                        :options="menuOpt"
                        placeholder="可选择，不选择默认最外层"
                        :props="{ checkStrictly: true, emitPath:false }"
                        clearable
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="菜单类型" prop="type">
                    <el-radio-group v-model="form.type">
                        <el-radio label="1">目录</el-radio>
                        <el-radio label="2">菜单</el-radio>
                        <el-radio label="3">按钮</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item v-if="form.type === '1' || form.type === '2'" label="前端路由路径">
                    <el-input v-model="form.path" size="small" placeholder="请填写前端路由路径"></el-input>
                </el-form-item>
                <el-form-item v-if="form.type === '2'" label="组件路径">
                  <el-input v-model="form.url" size="small" placeholder="请填写组件路径"></el-input>
                </el-form-item>
                <el-form-item v-if="form.type === '2'" label="图标">
                    <el-input v-model="form.icon" size="small" maxlength="30" placeholder="可填写菜单图标"></el-input>
                </el-form-item>
                <el-form-item v-if="form.type === '3'" label="按钮权限">
                    <el-input v-model="form.permission" size="small" placeholder="按钮权限"></el-input>
                </el-form-item>
                 <el-form-item label="排序">
                    <el-input v-model="form.sort" maxlength="3" size="small" placeholder="可填写菜单排序"></el-input>
                </el-form-item>
                <el-form-item label="菜单状态">
                    <el-switch v-model="form.enabled" inactive-color="#ff4949" active-value="1" inactive-value="0"></el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer">
                <el-button type="primary" @click="submit" size="small">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data(){
        return{
            loading:true,
            parentOsId:'',
            osOpt:[],
            tableData: [],
            form:{
                name:'',
                type:'',
                pcode:'',
                sort:'',
                enabled:'1',
                path:'',
                icon:'',
                // remarks:'',

            },
            menuOpt:[],
            rules:{
                name: [
                    { required: true, message: '请填写菜单名称', trigger: 'blur' },
                    { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
                ],
                type: [
                    { required: true, message: '请选择菜单类型', trigger: 'change' }
                ],
                // path:[
                //     { required: true, message: '请输入路由路径', trigger: 'blur' }
                // ]
            },
            dialogTitle:1,
            dialog:false,
            arrayExpand:['01000000','02000000','03000000']
        }
    },
    methods:{
        // 筛选
        search(){
            this.loading = true;
            !this.parentOsId ?
                setTimeout(()=>{
                    this.loadData(0)
                },500)
            : setTimeout(()=>{
                this.loadData(this.parentOsId);
            },500)
        },
        reset(){
            this.parentOsId = '';
            this.search();
        },
        // 表格开关切换状态
        // changeSatus(row){
        //     console.log(row);
        //     let text = row.enabled === '0'? '显示': '隐藏';
        //     this.$confirm(`确定要${text}该菜单吗?`, '提示', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //         type: 'warning'
        //     }).then(() => {
        //         this.$message({
        //             type: 'success',
        //             message: '操作成功!',
        //             duration:1500
        //         })
        //     }).catch(() => {})
        // },
        // 增加用户
        handleAdd(){
            this.dialogTitle = 1;
            this.dialog = true;
        },
        // 编辑用户信息
        handleEdit(row){
            this.dialogTitle = 2;
            this.dialog = true;
            this.form = {...row}
        },
        // 关闭对话框
        dialogClose(){
            this.form = {
                name:'',
                type:'',
                pcode:'',
                sort:'',
                enabled:'1',
                path:'',
                icon:'',
            }
            setTimeout(()=>{
                this.$refs['form'].clearValidate();
            },10)
        },
        // 提交保存
        submit(){
            let data = {...this.form};
            if(!data.pcode){
                // 修复-搜索某平台下无法添加最外层菜单
                this.parentOsId ? data.pcode = this.parentOsId :data.pcode = '0'
            }
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if(this.dialogTitle === 1){
                        // 新增
                        delete data.id;
                        this.$api.menuSave(data).then( d=> {
                            this.$message({
                                type: 'success',
                                message: '创建成功!'
                            });
                            this.dialog = false;
                            this.search();
                        })
                    }else if(this.dialogTitle === 2){
                        // 修改
                        this.$api.menuUpdate(data).then( d=> {
                            this.$message({
                                type: 'success',
                                message: '更新成功!'
                            });
                            this.dialog = false;
                            this.search();
                        })
                    }

                } else {
                    return false;
                }
            });
        },
        // 删除用户
        handleDelete(id){
            console.log(id);
            this.$confirm('确定要删除该菜单吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.menuDel(id).then( d =>{
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                        duration:1500
                    });
                    this.search();
                })
            }).catch(() => {})
        },
        // 遍历格式化树结构
        filterJson(arr) {
            const res = [];
            arr.forEach(v => {
                let tmp = { ...v };
                    tmp.label = v.name;
                    tmp.value = v.id;
                    if (tmp.children?.length>0) {
                        tmp.children = this.filterJson(tmp.children)
                    }else{
                        tmp.children = undefined;   // 修复-级联菜单最后一级暂无数据问题
                    }
                    res.push(tmp)
            })
            return res;
        },
        loadData(data){
            return new Promise( (resolve,rejuect) => {
                this.$api.menuTreeByUser().then( d => {
                    if(d?.length){
                        this.tableData = d;
                        this.menuOpt = this.filterJson(this.tableData);
                        // 修复 反复调用函数导致的筛选框---平台问题
                        !this.parentOsId ?
                            this.osOpt = this.filterJson(d)
                        : void 0;
                    }else{
                        this.menuOpt = [];
                        this.tableData = [];
                    }
                    this.loading = false;
                    resolve();
                })
            })
        }
    },
    mounted(){
        this.loadData(0);
    }
}
</script>

<style lang="scss" scoped>
.menu{
    width: calc(100% - 10px);
    height: 100%;
    overflow-y: auto;
    .condition{
        margin-bottom: 10px;
        .el-col{
            display: inline-block;
            margin: 5px 0;
        }
    }
    .table-inner{
        .handle-0{
            margin-bottom: 10px;
        }
        .pages-0{
            margin-top: 10px;
            text-align: right;
        }
    }
}
</style>
