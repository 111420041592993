<template>
    <div class="versions">
        <el-card class="glo-mgb10">
            <div class="glo-title">基本信息</div>
            <div class="inner">
                <el-form>
                    <el-form-item label="平台名称" label-width="80px">
                        <span>单点登录管理平台</span>
                    </el-form-item>
                    <el-form-item label="平台说明" label-width="80px">
                        <span>集中管理一体化-单点登录管理系统，集中控制平台项目平稳运行</span>
                    </el-form-item>
                    <el-form-item label="当前版本" label-width="80px">
                        <span>v1.0.0.0628</span>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
        <el-card>
            <div class="glo-title">更新日志</div>
            <el-collapse v-model="activeName" accordion>
                <el-collapse-item name="1">
                    <template slot="title">
                        <div class="glo-flex-justify-between">
                            <div>v1.0.0.0628</div>
                            <div class="date">2021.06.28</div>
                        </div>
                    </template>
                    <ol>
                        <li>优化-单点登录首页界面布局兼容屏幕大小比例显示</li>
                        <li>优化-单点登录菜单管理首次显示仅展开一级菜单</li>
                        <li>优化-首页个人信息组件获取缓存加载信息数据</li>
                        <li>优化-封装集中处理权限个人信息接口</li>
                        <li>优化-角色管理按状态筛选更改整型状态类型为字符串类型</li>
                        <li>优化-版本信息模块更新日志版面数据展示</li>
                        <li>修复-用户管理条件筛选修复分页加载传值问题</li>
                        <li>修复-单点登录日志查询筛选条件以及分页导致的页码不一致问题</li>
                    </ol>
                </el-collapse-item>
                <el-collapse-item name="2">
                    <template slot="title">
                        <div class="glo-flex-justify-between">
                            <div>v1.0.0.0502</div>
                            <div class="date">2021.05.02</div>
                        </div>
                    </template>
                    <ol>
                        <li>新增日志管理模块以及实现筛选模块功能</li>
                        <li>新增实现修改个人信息和密码以及退出登录</li>
                        <li>优化菜单管理以及角色管理状态更改为只读模式，仅在编辑时可更改</li>
                        <li>修复各模块条件筛选导致的不刷新数据bug</li>
                        <li>修复日志筛选日期无法清除问题以及401问题后端返回域名跳转</li>
                        <li>修复菜单树tree组件不传父级id，导致的回显问题</li>
                        <li>修复菜单管理当查询平台菜单后，创建菜单父级菜单选项为上一次查询的菜单选项</li>
                    </ol>
                </el-collapse-item>
                <el-collapse-item name="3">
                    <template slot="title">
                        <div class="glo-flex-justify-between">
                            <div>v1.0.0.0316</div>
                            <div class="date">2021.03.16</div>
                        </div>
                    </template>
                    <ol>
                        <li>新增实现机构/用户/角色/菜单的模块化具体功能</li>
                        <li>新增实现机构与用户的绑定关系</li>
                        <li>新增实现角色分配菜单权限功能</li>
                        <li>新增实现用户分配角色权限的功能</li>
                        <li>新增首页用户信息显示内容</li>
                        <li>新增用户添加权限角色管理字段以及筛选条件查询</li>
                        <li>新增各模块页面添加筛选条件模块</li>
                        <li>优化角色管理字段表格展示信息</li>
                    </ol>
                </el-collapse-item>
            </el-collapse>
        </el-card>
    </div>
</template>

<script>
export default {
    data(){
        return{
            activeName: '1'
        }
    }
}
</script>

<style lang="scss" scoped>
.versions{
    width: calc(100% - 10px);
    .el-form-item{
        margin-bottom: 0;
    }
    .date{color: #ca4440;padding-left: 10px;}
    .glo-flex-justify-between{
        width: 100%;
        padding: 0 10px;
    }
}
</style>