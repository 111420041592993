<template>
    <div class="user">
        <el-card class="condition">
            <el-row :gutter="20">
                <el-col :span="4" :xs="16" :sm="6" :lg="5" :xl="4"><el-input placeholder="请输入用户账号" v-model="filtrate.loginName" size="small" clearable></el-input></el-col>
                <!-- <el-col :span="4" :xs="16" :sm="6" :lg="5" :xl="4">
                    <el-select v-model="filtrate.status" placeholder="请选择用户状态" size="small" clearable>
                        <el-option
                        v-for="item in statusOpt"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-col> -->
                <el-col :span="4" :xs="16" :sm="6" :lg="5" :xl="4">
                    <el-cascader
                        v-model="filtrate.organId"
                        :options="orgOpt"
                        :props="{ checkStrictly: true,emitPath: false}"
                        placeholder="请选择所属机构"
                        size="small"
                        clearable
                    ></el-cascader>
                </el-col>
                <el-col :span="4" :xs="10" :sm="6" :lg="5" :xl="4">
                    <el-button type="success" size="small" round @click="search"><i class="el-icon-search glo-icon-mgr2"></i>搜索</el-button>
                    <el-button type="warning" size="small" round @click="reset"><i class="el-icon-refresh glo-icon-mgr2"></i>重置</el-button>
                </el-col>
            </el-row>
        </el-card>
        <el-card class="table-inner">
            <div class="handle-0">
                <el-button type="primary" size="small" @click="handleAdd">创建用户</el-button>
            </div>
            <el-table
                :data="tableData"
                border
                style="width: 100%"
                class="glo-table-switch"
                v-loading="loading"
                element-loading-text="加载中"
            >
                <el-table-column prop="userName" label="姓名" min-width="100"></el-table-column>
                <el-table-column prop="loginName" label="登录账号" min-width="110"></el-table-column>
                <el-table-column label="所属机构" min-width="110">
                    <template slot-scope="scope">
                        {{scope.row.organName || '暂无'}}
                    </template>
                </el-table-column>
                <el-table-column label="市场角色" min-width="110">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.marketRole === 1" type="success" size="mini">市场人员</el-tag>
                        <el-tag v-else-if="scope.row.marketRole === 0" size="mini">非市场人员</el-tag>
                        <el-tag v-else type="info" size="mini">暂无</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="电话" min-width="110">
                    <template slot-scope="scope">
                        {{scope.row.phone || '暂未设置'}}
                    </template>
                </el-table-column>
                <el-table-column label="邮箱" min-width="110">
                    <template slot-scope="scope">
                        {{scope.row.email || '暂未设置'}}
                    </template>
                </el-table-column>
                <!-- <el-table-column label="用户状态" min-width="100">
                    <template  slot-scope="scope">
                        <el-switch disabled v-model="scope.row.status" inactive-color="#ff4949" active-value="1" inactive-value="0"  @click.native="changeSatus(scope.row)"></el-switch>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column prop="creatTime" label="创建时间" min-width="150"></el-table-column> -->
                <el-table-column label="操作" min-width="160">
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.row)" type="primary" size="mini">编辑</el-button>
                        <el-button @click="handleDelete(scope.row.id)" type="danger" size="mini">删除</el-button>
                        <el-button @click="submitPassword(scope.row)" type="warning" size="mini">密码重置</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pages-0">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pages.current"
                    :page-sizes="pages.option"
                    :page-size="pages.size"
                    layout="total, sizes, prev, pager, next, jumper" :total="pages.total"
                ></el-pagination>
            </div>
        </el-card>
        <el-dialog
            :title="dialogTitle=== 1 ? '创建用户' : '编辑用户' "
            :visible.sync="dialog"
            width="30%"
            @closed="dialogClose"
            :close-on-click-modal="false"
        >
            <el-form ref="userForm" :rules="rules" :model="userForm" label-width="80px">
                <el-form-item label="姓名" prop="userName">
                    <el-input v-model="userForm.userName" size="small" placeholder="请填写用户姓名"></el-input>
                </el-form-item>
                <el-form-item label="上级机构" prop="organId">
                    <el-cascader
                        v-model="userForm.organId"
                        :options="orgOpt"
                        :props="{ checkStrictly: true,emitPath: false}"
                        placeholder="请选择上级机构"
                        size="small"
                        clearable
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="账号" prop="loginName">
                    <el-input v-model="userForm.loginName" maxlength="16" size="small" placeholder="请填写账号"></el-input>
                </el-form-item>
                <el-form-item v-if="dialogTitle===1" label="密码" prop="loginPassword">
                    <el-input v-model="userForm.loginPassword" maxlength="16" size="small" placeholder="请填写密码" show-password></el-input>
                </el-form-item>
                <!-- <el-form-item v-if="dialogTitle===2" label="密码">
                    <el-input v-model="userForm.loginPassword" size="small" placeholder="请填写密码" show-password></el-input>
                </el-form-item> -->
                <el-form-item label="电话">
                    <el-input v-model="userForm.phone" size="small" maxlength="11" placeholder="请填写电话"></el-input>
                </el-form-item>
                <el-form-item label="邮箱">
                    <el-input v-model="userForm.email" size="small" maxlength="32" placeholder="请填写邮箱"></el-input>
                </el-form-item>
                <el-form-item label="市场角色" prop="marketRole">
                    <el-radio-group v-model="userForm.marketRole">
                        <el-radio v-for="(item,index) in marketRoleOpt" :key="index" :label="item.value">{{item.label}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="权限角色" prop="roleIdList">
                    <el-checkbox-group v-model="userForm.roleIdList">
                        <el-checkbox v-for="(item,index) in userRoleOpt" :key="index" :label="item.id">{{item.roleName}}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <!-- <el-form-item label="账户状态">
                    <el-switch v-model="userForm.status" inactive-color="#ff4949" active-value="1" inactive-value="0"></el-switch>
                </el-form-item> -->
            </el-form>
            <span slot="footer">
                <el-button type="primary" @click="submit" size="small">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data(){
        return{
            loading:true,
            // statusOpt: [
            //     { value: '1', label: '启用' },
            //     { value: '0',label: '停用'}
            // ],
            tableData: [],
            orgOpt:[],
            pages:{
                current:1,
                option:[8, 10, 12,20],
                size:10,
                total:0
            },
            filtrate:{
                loginName:'',
                organId:'',
                // status:'',
            },
            userForm:{
                userName:'',
                organId:'',
                loginName:'',
                loginPassword:'',
                phone:'',
                email:'',
                marketRole:0,
                roleIdList:[]
                // status:1
            },
            marketRoleOpt:[
                {value: 2,label: '客户'},
                {value: 1,label: '市场人员'},
                {value: 0,label: '非市场人员'}
            ],
            userRoleOpt:[],
            rules:{
                userName: [
                    { required: true, message: '请填写姓名', trigger: 'blur' }
                ],
                organId: [
                    { required: true, message: '请选择上级机构', trigger: 'change' }
                ],
                roleIdList: [
                    { required: true, message: '请选择权限角色', trigger: 'change' }
                ],
                loginName: [
                    { required: true, message: '请填写账号', trigger: 'blur' },
                    { min: 2, max: 16, message: '长度在 2 到 16 个字符', trigger: 'blur' }
                ],
                loginPassword: [
                    { required: true, message: '请填写密码', trigger: 'blur' }
                ]
            },
            dialogTitle:1,
            dialog:false
        }
    },
    methods:{
        handleSizeChange(size) {
            this.pages.current = 1;
            this.pages.size = size;
            this.loading = true;
            this.loadData();
        },
        handleCurrentChange(page) {
            this.loading = true;
            setTimeout( ()=>{
                this.pages.current = page;
                this.loadData();
            },100)
        },
        // 筛选-检索
        search(){
            this.loading = true;
            this.pages.current = 1;
            setTimeout(()=>{
                this.loadData();
            },500)
        },
        // 筛选-重置
        reset(){
            this.filtrate={
                loginName:'',
                organId:'',
                // status:'',
            }
            this.search();
        },
        // 获取角色列表
        roleInfo(){
            return new Promise((resolve,rejuect) => {
                this.$api.roleList().then(d => {
                    console.log(d);
                    if(d?.length){
                        this.userRoleOpt = d;
                    }
                    resolve();
                })
            })
        },
        // 增加用户
        handleAdd(){
            this.dialogTitle = 1;
            this.dialog = true;
        },
        // 编辑用户信息
        handleEdit(row){
            this.dialogTitle = 2;
            this.dialog = true;
            this.userForm = {...row}
            console.log(this.userForm);
            this.userForm.loginPassword = '';
        },
        // 关闭对话框
        dialogClose(){
            this.userForm = {
                userName:'',
                organId:'',
                loginName:'',
                loginPassword:'',
                phone:'',
                email:'',
                marketRole:1,
                roleIdList:[]
                // status:1
            }
            setTimeout(()=>{
                this.$refs['userForm'].clearValidate();
            },10)
        },
        // 提交保存
        submit(){
            let data = {...this.userForm};
            this.$refs['userForm'].validate((valid) => {
                if (valid) {
                    if(this.dialogTitle === 1){
                        // 新增
                        delete data.id;
                        this.$api.userSave(data).then( d=> {
                            this.$message({
                                type: 'success',
                                message: '创建成功!'
                            });
                            this.dialog = false;
                            this.loading = true;
                            this.pages.current = 1;
                            setTimeout(()=>{
                                this.loadData();
                            },500)
                        })
                    }else if(this.dialogTitle === 2){
                        // 修改
                        delete data.loginPassword;
                        delete data.organName;
                        this.$api.userUpdate(data).then( d=> {
                            this.$message({
                                type: 'success',
                                message: '更新成功!'
                            });
                            this.dialog = false;
                            this.loading = true;
                            this.pages.current = 1;
                            setTimeout(()=>{
                                this.loadData();
                            },500)
                        })
                    }

                } else {
                    return false;
                }
            });
        },
        // 删除用户
        handleDelete(id){
            console.log(id);
            this.$confirm('确定要删除该用户吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.userDel(id).then( d =>{
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                        duration:1500
                    });
                    this.loading = true;
                    this.pages.current = 1;
                    setTimeout(()=>{
                        this.loadData();
                    },500)
                })
            }).catch(() => {})
        },
        // 重置用户密码
        submitPassword(row){
            let data = {
                userId: row.id,
                loginName: row.loginName
            }
            this.$confirm('确定要重置次用户密码?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.adminReset(data).then( d =>{
                    this.$message({
                        type: 'success',
                        message: '重置成功!',
                        duration:1500
                    });
                    this.loading = true;
                    this.pages.current = 1;
                    setTimeout(()=>{
                        this.loadData();
                    },500)
                })
            }).catch(() => {})
        },
        // 获取机构树
        getOrgTree(){
            return new Promise((resolve,rejuect)=>{
                this.$api.orgTree().then( d => {
                    console.log(d);
                    if(d?.length){
                        this.orgOpt = this.filterJson(d);
                    }
                })
            })
        },
        // 遍历格式化树结构
        filterJson(arr) {
            const res = [];
            arr.forEach(v => {
                let tmp = { ...v };
                    tmp.label = v.orgName;
                    tmp.value = v.id;
                    if (tmp.children.length>0) {
                        tmp.children = this.filterJson(tmp.children)
                    }else{
                        tmp.children = undefined;   // 修复-用户管理选择所属部门时部门级联菜单最后一级暂无数据问题
                    }
                    res.push(tmp)
            })
            return res;
        },
        loadData(){
            let data = {
                loginName:this.filtrate.loginName,
                organId:this.filtrate.organId,
            }
            return new Promise( (resolve,rejuect) => {
                this.$api.userPage(this.pages.current,this.pages.size,data).then( d => {
                    if(d?.records?.length){
                        this.tableData = d.records;
                        this.pages.total = +d.total;
                    }else{
                        this.tableData = [];
                    }
                    this.loading = false;
                    resolve();
                })
            })
        }
    },
    mounted(){
        this.loadData();
        this.getOrgTree();
        this.roleInfo();
    }
}
</script>

<style lang="scss" scoped>
.user{
    width: calc(100% - 10px);
    height: 100%;
    overflow-y: auto;
    .condition{
        margin-bottom: 10px;
        .el-col{
            display: inline-block;
            margin: 5px 0;
        }
    }
    .table-inner{
        .handle-0{
            margin-bottom: 10px;
        }
        .pages-0{
            margin-top: 10px;
            text-align: right;
        }
    }
}
</style>
