<template>
    <div class="smsUser">
        <el-card class="condition">
            <el-row :gutter="20">
                <el-col :span="4" :xs="16" :sm="6" :lg="5" :xl="4"><el-input placeholder="请输入联系人名称" v-model="filtrate.name" size="small" clearable></el-input></el-col>
                <el-col :span="4" :xs="16" :sm="6" :lg="5" :xl="4">
                    <el-select v-model="filtrate.enableFlag" placeholder="请选择联系人状态" size="small" clearable>
                        <el-option
                        v-for="item in statusOpt"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="4" :xs="10" :sm="7" :lg="5" :xl="4">
                    <el-button type="success" size="small" round @click="search"><i class="el-icon-search glo-icon-mgr2"></i>搜索</el-button>
                    <el-button type="warning" size="small" round @click="reset"><i class="el-icon-refresh glo-icon-mgr2"></i>重置</el-button>
                </el-col>
            </el-row>
        </el-card>
        <el-card class="table-inner">
            <div class="handle-0">
                <el-button type="primary" size="small" @click="handleAdd">创建联系人</el-button>
            </div>
            <el-table
                :data="tableData"
                border
                style="width: 100%"
                class="glo-table-switch"
                v-loading="loading"
                element-loading-text="加载中"
            >
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column label="手机号">
                    <template slot-scope="scope">
                        <span>{{scope.row.phone || '暂无'}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="描述">
                    <template slot-scope="scope">
                        <span>{{scope.row.description || '暂无'}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="联系人状态">
                    <template  slot-scope="scope">
                        <el-switch disabled v-model="scope.row.enableFlag" inactive-color="#ff4949" active-value="1" inactive-value="0"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.row)" type="primary" size="mini">编辑</el-button>
                        <el-button @click="handleDelete(scope.row.id)" type="danger" size="mini">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pages-0">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pages.current"
                    :page-sizes="pages.option"
                    :page-size="pages.size"
                    layout="total, sizes, prev, pager, next, jumper" :total="pages.total"
                ></el-pagination>
            </div>
        </el-card>

        <el-dialog
            :title="dialogTitle=== 1 ? '创建联系人' : '编辑联系人' "
            :visible.sync="dialogEdit"
            width="36%"
            @closed="dialogCloseEdit"
        >
            <el-form ref="smsUserForm" :rules="rules" :model="smsUserForm" label-width="80px">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="smsUserForm.name" maxlength="12" size="small" placeholder="请填写姓名"></el-input>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input v-model="smsUserForm.phone" maxlength="11" size="small" placeholder="手机号"></el-input>
                </el-form-item>
                <el-form-item label="描述">
                    <el-input v-model="smsUserForm.description" maxlength="120" type="textarea" :rows="4" size="small" placeholder="可填写联系人描述"></el-input>
                </el-form-item>
                <el-form-item label="联系人状态">
                    <el-switch v-model="smsUserForm.enableFlag" inactive-color="#ff4949" active-value="1" inactive-value="0"></el-switch>
                </el-form-item>
                <div class="menu-dialog">
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                    <div style="margin: 15px 0;"></div>
                    <el-checkbox-group v-model="projPropList" @change="handleCheckedCitiesChange" style="overflow-y: auto;height: 88%;">
                        <el-checkbox v-for="proj in projectList" :label="proj.projCode" :key="proj.projCode">{{proj.projName+proj.projDetail}}</el-checkbox>
                    </el-checkbox-group>
                </div>
            </el-form>
            <span slot="footer">
                <el-button type="primary" @click="submit" size="small">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data(){
        return{
            loading:true,
            pages:{
                current:1,
                option:[8, 10, 12,20],
                size:10,
                total:0
            },
            statusOpt:[
                { value: 1, label: '启用' },
                { value: 0,label: '停用'},
            ],
            tableData: [],
            currentPage:1,
            filtrate:{
                name:'',
                enableFlag:''
            },
            smsUserForm:{
                name:'',
                phone:'',
                description:'',
                enableFlag:'1'
            },
            projectList:[],
            projCodeList:[],
            projPropList:[],
            rules:{
                name: [
                    { required: true, message: '请填写姓名', trigger: 'blur' },
                    { min: 2, max: 12, message: '长度在2 到 12 个字符', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请填写手机号', trigger: 'blur' },
                    { min: 2, max: 12, message: '长度11 个字符', trigger: 'blur' }
                ],
            },
            dialogTitle:1,
            dialogEdit:false,
            dialogData:false,
            isIndeterminate: true,
            checkAll:false

        }
    },
    methods:{
        handleSizeChange(size) {
            this.pages.current = 1;
            this.pages.size = size;
            this.loading = true;
            this.loadData();
        },
        handleCurrentChange(page) {
            this.loading = true;
            setTimeout( ()=>{
                this.pages.current = page;
                this.loadData();
            },100)
        },
        handleCheckAllChange(val) {
          this.projPropList = val ? this.projCodeList : [];
          this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
          let checkedCount = value.length;
          this.checkAll = checkedCount === this.projectList.length;
          this.isIndeterminate = checkedCount > 0 && checkedCount < this.projectList.length;
        },
        // 筛选-检索
        search(){
            this.loading = true;
            this.pages.current=1;
            setTimeout(()=>{
                this.loadData();
            },500)
        },
        // 筛选-重置
        reset(){
            this.filtrate={
                name:'',
                enableFlag: ''
            }
            this.search();
        },
        // 增加用户
        handleAdd(){
            this.dialogTitle = 1;
            this.dialogEdit = true;
        },
        // 编辑用户信息
        handleEdit(row){
            this.dialogTitle = 2;
            this.dialogEdit = true;
            this.smsUserForm = {...row}
            this.projPropList = row.projectCode.split(',');
        },
        // 关闭编辑对话框
        dialogCloseEdit(){
            this.smsUserForm = {
                smsUserName:'',
                sortIndex:'',
                smsUserDesc:'',
                enableFlag:'1'
            }
            setTimeout(()=>{
                this.$refs['smsUserForm'].clearValidate();
            },10)
        },
        // 提交保存
        submit(){
            let data = {...this.smsUserForm};
            data.projectCode = this.projPropList.toString();
            this.$refs['smsUserForm'].validate((valid) => {
                if (valid) {
                    if(this.dialogTitle === 1){
                        // 新增
                        delete data.id;
                        this.$api.smsSave(data).then( d=> {
                            this.$message({
                                type: 'success',
                                message: '创建成功!'
                            });
                            this.dialogEdit = false;
                            this.loading = true;
                            this.pages.current = 1;
                            setTimeout(()=>{
                                this.loadData();
                            },500)
                        })
                    }else if(this.dialogTitle === 2){
                        // 修改
                        this.$api.smsUpdate(data).then( d=> {
                            this.$message({
                                type: 'success',
                                message: '更新成功!'
                            });
                            this.dialogEdit = false;
                            this.loading = true;
                            this.pages.current = 1;
                            setTimeout(()=>{
                                this.loadData();
                            },500)
                        })
                    }

                } else {
                    return false;
                }
            });
        },
        // 删除用户
        handleDelete(id){
            this.$confirm('确定要删除该联系人吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.smsDel(id).then( d =>{
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                        duration:1500
                    });
                    this.loading = true;
                    this.pages.current = 1;
                    setTimeout(()=>{
                        this.loadData();
                    },500)
                })
            }).catch(() => {})
        },
        //获取项目信息
        projectInfo(){
          return new Promise( (resolve,rejuect) => {
            this.$api.projectList(0).then( d => {
              let me = this;
              this.projectList  = d||[];
              this.projectList.map(function (item){
                me.projCodeList.push(item.projCode);
              })
              this.loading = false;
              resolve();
            })
          })
        },
        loadData(){
            let data = {
                
            }
            return new Promise( (resolve,rejuect) => {
                this.$api.smsPage(this.pages.current,this.pages.size,data).then( d => {
                    if(d?.records?.length){
                        this.tableData = d.records;
                        this.pages.total = +d.total;
                    }else{
                        this.tableData = [];
                    }
                    this.loading = false;
                    resolve();
                })
            })
        }
    },
    mounted(){
        this.projectInfo();
        this.loadData();
    }
}
</script>

<style lang="scss" scoped>
.smsUser{
    width: calc(100% - 10px);
    height: 100%;
    overflow-y: auto;
    .condition{
        margin-bottom: 10px;
        .el-col{
            display: inline-block;
            margin: 5px 0;
        }
    }
    .table-inner{
        .handle-0{
            margin-bottom: 10px;
        }
        .pages-0{
            margin-top: 10px;
            text-align: right;
        }
    }
    .menu-dialog{
        border: 1px solid #ccc;
        padding: 4px;
        height: 20rem;
    }
    .mgb10{
        margin-bottom: 10px;
    }
}
</style>
