<template>
    <div class="role">
        <el-card class="condition">
            <el-row :gutter="20">
                <el-col :span="4" :xs="16" :sm="6" :lg="5" :xl="4"><el-input placeholder="请输入角色名称" v-model="filtrate.roleName" size="small" clearable></el-input></el-col>
                <el-col :span="4" :xs="16" :sm="6" :lg="5" :xl="4">
                    <el-select v-model="filtrate.status" placeholder="请选择角色状态" size="small" clearable>
                        <el-option
                        v-for="item in statusOpt"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="4" :xs="10" :sm="7" :lg="5" :xl="4">
                    <el-button type="success" size="small" round @click="search"><i class="el-icon-search glo-icon-mgr2"></i>搜索</el-button>
                    <el-button type="warning" size="small" round @click="reset"><i class="el-icon-refresh glo-icon-mgr2"></i>重置</el-button>
                </el-col>
            </el-row>
        </el-card>
        <el-card class="table-inner">
            <div class="handle-0">
                <el-button type="primary" size="small" @click="handleAdd">创建角色</el-button>
            </div>
            <el-table
                :data="tableData"
                border
                style="width: 100%"
                class="glo-table-switch"
                v-loading="loading"
                element-loading-text="加载中"
            >
                <!-- <el-table-column prop="id" label="编号" min-width="80"></el-table-column> -->
                <el-table-column prop="roleName" label="角色名称" min-width="100"></el-table-column>
                <el-table-column label="排序">
                    <template slot-scope="scope">
                        <span>{{scope.row.sortIndex || '暂无'}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="描述">
                    <template slot-scope="scope">
                        <span>{{scope.row.roleDesc || '暂无'}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="角色状态">
                    <template  slot-scope="scope">
                        <!-- <el-switch disabled v-model="scope.row.status" inactive-color="#ff4949" :active-value="1" :inactive-value="0"  @click.native="changeSatus(scope.row)"></el-switch> -->
                        <el-switch disabled v-model="scope.row.status" inactive-color="#ff4949" active-value="1" inactive-value="0"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="400">
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.row)" type="primary" size="mini">编辑</el-button>
                        <el-button @click="handleMenu(scope.row)" type="success" size="mini">菜单权限</el-button>
                        <el-button @click="handleData(scope.row)" type="success" size="mini">数据权限</el-button>
                        <el-button @click="handleDelete(scope.row.id)" type="danger" size="mini">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pages-0">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pages.current"
                    :page-sizes="pages.option"
                    :page-size="pages.size"
                    layout="total, sizes, prev, pager, next, jumper" :total="pages.total"
                ></el-pagination>
            </div>
        </el-card>

        <el-dialog
            :title="dialogTitle=== 1 ? '创建角色' : '编辑角色' "
            :visible.sync="dialogEdit"
            width="36%"
            @closed="dialogCloseEdit"
        >
            <el-form ref="roleForm" :rules="rules" :model="roleForm" label-width="80px">
                <el-form-item label="角色名称" prop="roleName">
                    <el-input v-model="roleForm.roleName" maxlength="12" size="small" placeholder="请填写角色名称"></el-input>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input v-model="roleForm.sortIndex" maxlength="3" size="small" placeholder="请填写排序字段"></el-input>
                </el-form-item>
                <el-form-item label="描述">
                    <el-input v-model="roleForm.roleDesc" maxlength="120" type="textarea" :rows="4" size="small" placeholder="可填写角色描述"></el-input>
                </el-form-item>
                <el-form-item label="角色状态">
                    <el-switch v-model="roleForm.status" inactive-color="#ff4949" active-value="1" inactive-value="0"></el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer">
                <el-button type="primary" @click="submit" size="small">提 交</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="菜单权限分配"
            :visible.sync="dialogMenu"
            width="36%"
            @closed="dialogCloseMenu"
        >
            <el-checkbox-group v-model="menuPropList" @change="watchProp1" class="mgb10">
                <el-checkbox :label="1">展开/折叠</el-checkbox>
            </el-checkbox-group>
            <el-checkbox-group v-model="menuPropList" @change="watchProp2" class="mgb10">
                <el-checkbox :label="2">全选/全不选</el-checkbox>
            </el-checkbox-group>
            <div class="menu-dialog">
                <el-tree
                    :data="menuOpt"
                    show-checkbox
                    node-key="id"
                    ref="tree"
                    highlight-current
                    :expand-on-click-node="false"
                    default-expand-all
                    :props="{children: 'children',label: 'label'}"
                    @check="handleCheck"
                >
                </el-tree>
            </div>
            <span slot="footer">
                <el-button type="primary" @click="submitMenuList" size="small">提 交</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="项目权限分配"
            :visible.sync="dialogData"
            width="36%"
            @closed="dialogCloseData"
            :close-on-click-modal="false"
        >

          <div class="menu-dialog">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
            <div style="margin: 15px 0;"></div>
            <el-checkbox-group v-model="projPropList" @change="handleCheckedCitiesChange">
              <el-checkbox v-for="proj in projectList" :label="proj.projCode" :key="proj.projCode">{{proj.projName+proj.projDetail}}</el-checkbox>
            </el-checkbox-group>
          </div>
          <span slot="footer">
                  <el-button type="primary" @click="submitProjectList" size="small">提 交</el-button>
              </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data(){
        return{
            loading:true,
            statusOpt:[
                { value: '1', label: '正常' },
                { value: '0',label: '停用'},
            ],
            pages:{
                current:1,
                option:[8, 10, 12,20],
                size:10,
                total:0
            },
            tableData: [],
            currentPage:1,
            filtrate:{
                roleName:'',
                status:'',
            },
            roleForm:{
                roleName:'',
                sortIndex:'',
                roleDesc:'',
                status:'1'
                // scope:[],
            },
            menuOpt:[],
            menuIdList:[],
            projectList:[],
            projCodeList:[],
            menuPropList:[1],
            projPropList:[],
            rules:{
                roleName: [
                    { required: true, message: '请填写角色名称', trigger: 'blur' },
                    { min: 2, max: 12, message: '长度在2 到 12 个字符', trigger: 'blur' }
                ]
            },
            dialogTitle:1,
            dialogEdit:false,
            dialogMenu:false,
            dialogData:false,
            isIndeterminate: true,
            checkAll:false

        }
    },
    methods:{
        // 菜单树选项监听---折叠与全选方法分开，防止相互影响
        watchProp1(val){
            let isFold = val.includes(1);
            if(isFold){
                this.unFoldAll2(this.menuOpt);
            }else{
                this.collapseAll2(this.menuOpt);
            }
        },
        watchProp2(val){
            let isCheckedAll = val.includes(2);
            if(isCheckedAll){
                this.$refs.tree.setCheckedNodes(this.menuOpt); //全选
                this.menuIdList = this.$refs.tree.getCheckedKeys();
            }else{
                this.$refs.tree.setCheckedKeys([]); //全不选
                this.menuIdList = [];
            }
        },
        // 全部展开
        unFoldAll2(data) {
            data.forEach((el) => {
                this.$refs.tree.store.nodesMap[el.id].expanded = true;
                el.children?.length > 0
                ? this.unFoldAll2(el.children)
                : void 0; // 子级递归
            });
        },
        // 全部折叠
        collapseAll2(data) {
            data.forEach((el) => {
                this.$refs.tree.store.nodesMap[el.id].expanded = false;
                el.children?.length > 0
                ? this.collapseAll2(el.children)
                : void 0; // 子级递归
            });
        },
        // 菜单树选择
        handleCheck(c,d){
            this.menuIdList = d.checkedKeys.concat(d.halfCheckedKeys);
        },
        handleSizeChange(size) {
            this.pages.current = 1;
            this.pages.size = size;
            this.loading = true;
            this.loadData();
        },
        handleCurrentChange(page) {
            this.loading = true;
            setTimeout( ()=>{
                this.pages.current = page;
                this.loadData();
            },100)
        },
        handleCheckAllChange(val) {
          this.projPropList = val ? this.projCodeList : [];
          this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
          let checkedCount = value.length;
          this.checkAll = checkedCount === this.projectList.length;
          this.isIndeterminate = checkedCount > 0 && checkedCount < this.projectList.length;
        },
        // 筛选-检索
        search(){
            this.loading = true;
            this.pages.current=1;
            setTimeout(()=>{
                this.loadData();
            },500)
        },
        // 筛选-重置
        reset(){
            this.filtrate={
                roleName:'',
                status:'',
                // status:1,
            }
            this.search();
        },
        // 表格开关切换状态
        // changeSatus(row){
        //     console.log(row);
        //     let text = row.status === 1? '停用': '启用';
        //     this.$confirm(`确定要${text}该角色吗?`, '提示', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //         type: 'warning'
        //     }).then(() => {
        //         // this.tableData.forEach( e => {
        //         //     if(e.id === row.id){
        //         //         row.status === 1 ?
        //         //         e.status = 0 : e.status = 1
        //         //     }
        //         // })
        //         this.$message({
        //             type: 'success',
        //             message: '操作成功!',
        //             duration:1500
        //         })
        //     }).catch(() => {})
        // },
        // 增加用户
        handleAdd(){
            this.dialogTitle = 1;
            this.dialogEdit = true;
        },
        // 编辑用户信息
        handleEdit(row){
            this.dialogTitle = 2;
            this.dialogEdit = true;
            this.roleForm = {...row}
        },
        // 菜单权限
        handleMenu(row){
            console.log(row.menuIdList);
            this.dialogMenu = true;
            this.roleForm.id = row.id;
            this.menuIdList = row.menuIdList;
            this.$nextTick(() => {
                const arr = []
                // 解决-tree组件不传父级id，导致的回显问题----getNode() 获取到当前节点，判断当前节点是否是叶子节点，是的话存入 arr 数组中，最后使用 setCheckedKeys() 将数据回显选中，从而实现父级的半选状态
                this.menuIdList.forEach(item => {
                    if (!this.$refs.tree.getNode(item).childNodes || !this.$refs.tree.getNode(item).childNodes.length) {
                        arr.push(item)
                    }
                })
                this.$refs.tree.setCheckedKeys(arr)
            })
        },
        // 数据权限
        handleData(row){
          this.checkAll = false;
          this.dialogData = true;
          this.roleForm.id = row.id;
          this.projPropList = row.projectList;
          // this.$nextTick(() => {
          //   const arr = []
          //   proj.forEach(item => {
          //     this.projPropList.push(item.projCode)
          //   })
          // })
        },
        // 关闭编辑对话框
        dialogCloseEdit(){
            this.roleForm = {
                roleName:'',
                sortIndex:'',
                roleDesc:'',
                // type:'',
                // scope:'',
                status:1
            }
            setTimeout(()=>{
                this.$refs['roleForm'].clearValidate();
            },10)
        },
        // 关闭菜单分配对话框
        dialogCloseMenu(){
            this.$refs.tree.setCheckedKeys([]);
            this.menuPropList = [1];
        },
        dialogCloseData(){
          this.projPropList = [];
        },
        // 提交保存
        submit(){
            let data = {...this.roleForm};
            this.$refs['roleForm'].validate((valid) => {
                if (valid) {
                    if(this.dialogTitle === 1){
                        // 新增
                        delete data.id;
                        this.$api.roleSave(data).then( d=> {
                            this.$message({
                                type: 'success',
                                message: '创建成功!'
                            });
                            this.dialogEdit = false;
                            this.loading = true;
                            this.pages.current = 1;
                            setTimeout(()=>{
                                this.loadData();
                            },500)
                        })
                    }else if(this.dialogTitle === 2){
                        // 修改
                        delete data.menuIdList;
                        this.$api.roleUpdate(data).then( d=> {
                            this.$message({
                                type: 'success',
                                message: '更新成功!'
                            });
                            this.dialogEdit = false;
                            this.loading = true;
                            this.pages.current = 1;
                            setTimeout(()=>{
                                this.loadData();
                            },500)
                        })
                    }

                } else {
                    return false;
                }
            });
        },
        // 提交菜单分配权限
        submitMenuList(){
            this.$api.roleMenu(this.roleForm.id,this.menuIdList).then(d => {
                this.dialogMenu = false;
                this.$message({
                    type: 'success',
                    message: '操作成功!',
                    duration:1500
                });
                this.loading = true;
                this.pages.current = 1;
                setTimeout(()=>{
                    this.loadData();
                },500)
            })
        },
        // 提交项目分配权限
        submitProjectList(){
          this.$api.roleProject(this.roleForm.id,this.projPropList).then(d => {
            this.dialogData = false;
            this.$message({
              type: 'success',
              message: '操作成功!',
              duration:1500
            });
            this.loading = true;
            this.pages.current = 1;
            setTimeout(()=>{
              this.loadData();
            },500)
          })
        },
        // 删除用户
        handleDelete(id){
            this.$confirm('确定要删除该角色吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.roleDel(id).then( d =>{
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                        duration:1500
                    });
                    this.loading = true;
                    this.pages.current = 1;
                    setTimeout(()=>{
                        this.loadData();
                    },500)
                })
            }).catch(() => {})
        },
        // 获取菜单树
        menuInfo(){
            return new Promise( (resolve,rejuect) => {
                this.$api.menuTree(0).then( d => {
                    if(d?.length){
                        this.menuOpt = this.filterJson(d);
                    }
                    this.loading = false;
                    resolve();
                })
            })
        },
        //获取项目信息
        projectInfo(){
          return new Promise( (resolve,rejuect) => {
            this.$api.projectList(0).then( d => {
              let me = this;
              this.projectList  = d||[];
              this.projectList.map(function (item){
                me.projCodeList.push(item.projCode);
              })
              this.loading = false;
              resolve();
            })
          })
        },

        // 遍历格式化树结构
        filterJson(arr) {
            const res = [];
            arr.forEach(v => {
                let tmp = { ...v };
                    tmp.label = v.name;
                    tmp.value = v.id;
                    if (tmp.children?.length>0) {
                        tmp.children = this.filterJson(tmp.children)
                    }else{
                        tmp.children = undefined;   // 修复-级联菜单最后一级暂无数据问题
                    }
                    res.push(tmp)
            })
            return res;
        },
        loadData(){
            let data = {
                roleName:this.filtrate.roleName,
                status:this.filtrate.status
            }
            return new Promise( (resolve,rejuect) => {
                this.$api.rolePage(this.pages.current,this.pages.size,data).then( d => {
                    if(d?.records?.length){
                        this.tableData = d.records;
                        this.pages.total = +d.total;
                    }else{
                        this.tableData = [];
                    }
                    this.loading = false;
                    resolve();
                })
            })
        }
    },
    mounted(){
        this.menuInfo();
        this.projectInfo();
        this.loadData();
    }
}
</script>

<style lang="scss" scoped>
.role{
    width: calc(100% - 10px);
    height: 100%;
    overflow-y: auto;
    .condition{
        margin-bottom: 10px;
        .el-col{
            display: inline-block;
            margin: 5px 0;
        }
    }
    .table-inner{
        .handle-0{
            margin-bottom: 10px;
        }
        .pages-0{
            margin-top: 10px;
            text-align: right;
        }
    }
    .menu-dialog{
        border: 1px solid #ccc;
        padding: 4px;
    }
    .mgb10{
        margin-bottom: 10px;
    }
}
</style>
