<template>
    <div class="applet">
        <el-card class="condition">
            <el-row :gutter="20">
                <el-col :span="4" :xs="16" :sm="6" :lg="5" :xl="4"><el-input placeholder="请输入用户账号" v-model="filtrate.loginName" size="small" clearable></el-input></el-col>
                <el-col :span="4" :xs="10" :sm="6" :lg="5" :xl="4">
                    <el-button type="success" size="small" round @click="search"><i class="el-icon-search glo-icon-mgr2"></i>搜索</el-button>
                    <el-button type="warning" size="small" round @click="reset"><i class="el-icon-refresh glo-icon-mgr2"></i>重置</el-button>
                </el-col>
            </el-row>
        </el-card>
        <el-card class="table-inner">
            <el-table
                :data="tableData"
                border
                style="width: 100%"
                class="glo-table-switch"
                v-loading="loading"
                element-loading-text="加载中"
            >
                <el-table-column prop="userName" label="姓名" min-width="100"></el-table-column>
                <el-table-column prop="url" label="头像" min-width="110">
                    <template slot-scope="scope">
        　　　　        <img :src="scope.row.url" width="40" height="40" class="head_pic"/>
        　　      </template>
                </el-table-column>
                <el-table-column prop="thirdUniqueAccount" label="第三方唯一用户id" min-width="100" :show-overflow-tooltip='true'></el-table-column>
                <el-table-column prop="type" label="标识第三方类型" min-width="110">
                    <template slot-scope="scope">
                        {{scope.row.type || '暂未设置'}}
                    </template>
                </el-table-column>
                <el-table-column prop="adminFlag" label="超级用户" min-width="100">
                    <template slot-scope="scope">
                        {{scope.row.adminFlag == '1' ? '有权限' : '无权限'}}
                    </template>
                </el-table-column>
                <el-table-column prop="xkDeviceFlag" label="新科设备管理" min-width="100">
                    <template slot-scope="scope">
                        {{scope.row.xkDeviceFlag == '1' ? '有权限' : '无权限'}}
                    </template>
                </el-table-column>
                <el-table-column prop="deviceParamFlag" label="设备参数" min-width="110">
                    <template slot-scope="scope">
                        {{scope.row.deviceParamFlag == '1' ? '有权限' : '无权限'}}
                    </template>
                </el-table-column>
                <el-table-column prop="otherFlag1" label="其他参数1" min-width="100">
                    <template slot-scope="scope">
                        {{scope.row.otherFlag1 || '暂未设置'}}
                    </template>
                </el-table-column>
                <el-table-column prop="otherFlag2" label="其他参数1" min-width="110">
                    <template slot-scope="scope">
                        {{scope.row.otherFlag2 || '暂未设置'}}
                    </template>
                </el-table-column>
                <el-table-column prop="otherFlag3" label="其他参数1" min-width="100">
                    <template slot-scope="scope">
                        {{scope.row.otherFlag3 || '暂未设置'}}
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="130">
                </el-table-column>
                <el-table-column label="操作" min-width="160">
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.row)" type="primary" size="mini">编辑</el-button>
                        <el-button @click="handleDelete(scope.row.id)" type="danger" size="mini">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pages-0">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pages.current"
                    :page-sizes="pages.option"
                    :page-size="pages.size"
                    layout="total, sizes, prev, pager, next, jumper" :total="pages.total"
                ></el-pagination>
            </div>
        </el-card>
        <el-dialog
            :title="dialogTitl = '编辑用户' "
            :visible.sync="dialog"
            width="30%"
            @closed="dialogClose"
            :close-on-click-modal="false"
        >
            <el-form ref="userForm" :rules="rules" :model="userForm" label-width="90px">
                <el-form-item label="超级用户" prop="adminFlag">
                    <el-select v-model="userForm.adminFlag" placeholder="请选择" clearable >
                        <el-option v-for="item in this.adminList" :key="item.value"
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="新科设备管理" prop="xkDeviceFlag">
                    <el-select v-model="userForm.xkDeviceFlag" placeholder="请选择" clearable >
                        <el-option v-for="item in this.adminList" :key="item.value"
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备参数" prop="deviceParamFlag">
                    <el-select v-model="userForm.deviceParamFlag" placeholder="请选择" size="large" clearable>
                        <el-option v-for="item in this.adminList" :key="item.value"
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="标识第三方类型" prop="type">
                    <el-input v-model="userForm.type" size="small" placeholder="请填写标识第三方类型" clearable ></el-input>
                </el-form-item>
                <el-form-item label="其他参数1" prop="otherFlag1">
                    <el-input v-model="userForm.otherFlag1" size="small" placeholder="请填写其他参数1" clearable ></el-input>
                </el-form-item>
                <el-form-item label="其他参数2" prop="otherFlag2">
                    <el-input v-model="userForm.otherFlag2" size="small" placeholder="请填写其他参数2" clearable ></el-input>
                </el-form-item>
                <el-form-item label="其他参数3" prop="otherFlag3">
                    <el-input v-model="userForm.otherFlag3" size="small" placeholder="请填写其他参数3" clearable ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer">
                <el-button type="primary" @click="submit" size="small">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data(){
        return{
            loading:true,
            tableData: [],
            pages:{
                current:1,
                option:[8, 10, 12,20],
                size:10,
                total:0
            },
            filtrate:{
                loginName:'',
                organId:'',
                // status:'',
            },
            adminList:[
                {
                    value: '0',
                    label: '无权限'
                },
                {
                    value: '1',
                    label: '有权限'
                }
            ],
            userForm:{
                userName:'',
                organId:'',
                loginName:'',
                loginPassword:'',
                phone:'',
                email:'',
                marketRole:1,
                roleIdList:[]
            },
            rules:{
                adminFlag: [
                    { required: true, message: '超级用户', trigger: 'blur' }
                ],
                xkDeviceFlag: [
                    { required: true, message: '新科设备管理', trigger: 'change' }
                ],
                deviceParamFlag: [
                    { required: true, message: '设备参数', trigger: 'blur' },
                ],
            },
            dialogTitle:1,
            dialog:false
        }
    },
    methods:{
        handleSizeChange(size) {
            this.pages.current = 1;
            this.pages.size = size;
            this.loading = true;
            this.loadData();
        },
        handleCurrentChange(page) {
            this.loading = true;
            setTimeout( ()=>{
                this.pages.current = page;
                this.loadData();
            },100)
        },
        // 筛选-检索
        search(){
            this.loading = true;
            this.pages.current = 1;
            setTimeout(()=>{
                this.loadData();
            },500)
        },
        // 筛选-重置
        reset(){
            this.filtrate={
                loginName:'',
                organId:'',
                // status:'',
            }
            this.search();
        },
        // 编辑用户信息
        handleEdit(row){
            this.dialogTitle = 2;
            this.dialog = true;
            this.userForm = {...row}
            console.log(this.userForm);
            this.userForm.loginPassword = '';
        },
        // 关闭对话框
        dialogClose(){
            this.userForm = {
                userName:'',
                organId:'',
                loginName:'',
                loginPassword:'',
                phone:'',
                email:'',
                marketRole:1,
                roleIdList:[]
                // status:1
            }
            setTimeout(()=>{
                this.$refs['userForm'].clearValidate();
            },10)
        },
        // 提交保存
        submit(){
            let data = {...this.userForm};
            this.$refs['userForm'].validate((valid) => {
                if (valid) {
                    // 修改
                    delete data.loginPassword;
                    delete data.organName;
                    this.$api.appletUserUpdate(data).then( d=> {
                        this.$message({
                            type: 'success',
                            message: '更新成功!'
                        });
                        this.dialog = false;
                        this.loading = true;
                        this.pages.current = 1;
                        setTimeout(()=>{
                            this.loadData();
                        },500)
                    })

                } else {
                    return false;
                }
            });
        },
        // 删除用户
        handleDelete(id){
            console.log(id);
            this.$confirm('确定要删除该用户吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.appletUserDel(id).then( d =>{
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                        duration:1500
                    });
                    this.loading = true;
                    this.pages.current = 1;
                    setTimeout(()=>{
                        this.loadData();
                    },500)
                })
            }).catch(() => {})
        },
        loadData(){
            let data = {
                loginName:this.filtrate.loginName,
                organId:this.filtrate.organId,
            }
            return new Promise( (resolve,rejuect) => {
                this.$api.appletUserPage(this.pages.current,this.pages.size,data).then( d => {
                    if(d?.records?.length){
                        this.tableData = d.records;
                        this.pages.total = +d.total;
                    }else{
                        this.tableData = [];
                    }
                    this.loading = false;
                    resolve();
                })
            })
        }
    },
    mounted(){
        this.loadData();
    }
}
</script>

<style lang="scss" scoped>
.applet{
    width: calc(100% - 10px);
    height: 100%;
    overflow-y: auto;
    .condition{
        margin-bottom: 10px;
        .el-col{
            display: inline-block;
            margin: 5px 0;
        }
    }
    .table-inner{
        .handle-0{
            margin-bottom: 10px;
        }
        .pages-0{
            margin-top: 10px;
            text-align: right;
        }
    }
}
</style>
