<template>
    <div id="dateContainer">
      <div class="nowTime">
        <div><i class="el-icon-caret-left" @click="preMon"></i></div>
        <div class="thisMon">
            <span v-text="ynow"></span>/
            <span v-text="mnow+1"></span>
        </div>
        <div><i class="el-icon-caret-right" @click="nextMon"></i></div>
      </div>
      <table border="1" id="table">
      </table>
    </div>
</template>
<script>
  export default {
    name:'date',
    data() {
      return {
        newDate: '',//当前的日期的信息
        ynow: '',//当前的年数
        mnow: '',//当前的月份
        dnow: '',//当前的天数
        firstDay: '',//第一天
        firstnow: '',//当前的星期
        m_days: [],
        tr_str: '',
      }
    },
    methods: {
      is_leap(year) {
        return (year%100==0?(year%400==0?1:0):(year%4==0?1:0));
      },
      //下面的是画表格的方法，这个方法会根据数据画出我们需要的表格
      drawTable() {
        var _this = this;
        var str = `
        <tr class="xiqi">
          <td>日</td>
          <td>一</td>
          <td>二</td>
          <td>三</td>
          <td>四</td>
          <td>五</td>
          <td>六</td>
        </tr>`;
        var idx = '',date_str = '',isRed = '',hasMsg='';
        for(var i = 0; i< _this.tr_str; i++) {
          str+='<tr>';
          for(var k = 0; k < 7; k++) {
              idx = i*7+k;
              isRed = (k===0||k===6)?"isRed":"";
              date_str=idx-_this.firstnow+1;
            (date_str<=0 || date_str>this.m_days[this.mnow]) ? date_str="&nbsp;" : date_str=idx-_this.firstnow+1;
            date_str == _this.dnow && _this.ynow == new Date().getFullYear() && _this.mnow == new Date().getMonth()
            ?hasMsg='<td class="thisDay" date="'+date_str +'"><span  class="'+isRed +'">'+date_str+'</span></td>':hasMsg='<td date="'+date_str +'"><span  class="'+isRed +'">'+date_str+'</span></td>';
            str+=hasMsg;
           }
          str+='</tr>';
        }
        var table = document.getElementById('table');
        table.innerHTML = str;
        
        // 当前日期点击事件
        let btn = document.getElementsByClassName('thisDay')?.[0];
        if(btn){
          btn.onclick=function(){
            _this.$notify.info({
              message: `当前日期：${_this.ynow}-${_this.mnow+1}-${_this.dnow}`,
              duration: 3000
            });
          }
        }
      },
      
      //两个参数代表的含义分别是this对象以及判断当前的操作是不是在进行月份的修改
      initDate(_this) {
        this.newDate = new Date();
        this.ynow = this.newDate.getFullYear();
        this.mnow = this.newDate.getMonth(); //月份
        this.dnow = this.newDate.getDate(); //今日日期
        this.disposeCon();
      },
      disposeCon(){
        this.firstDay = new Date(this.ynow,this.mnow,1); //第一天
        this.firstnow=this.firstDay.getDay();   //星期几
        this.m_days = [31,28+this.is_leap(this.ynow),31,30,31,30,31,31,30,31,30,31];
        this.tr_str = Math.ceil((this.m_days[this.mnow] + this.firstnow)/7);
        this.drawTable();
      },
      preMon() {
        if(this.mnow>1){
          this.mnow--;
        }else{
          this.mnow = 11;
          this.ynow --;
        }
        this.disposeCon();
      },
      nextMon() {
        if(this.mnow<11){
          this.mnow++;
        }else{
          this.mnow = 0;
          this.ynow ++;
        }
        this.disposeCon();
      }
    },
    mounted() {
      //画出当前的月份的天数对应的表格
      this.initDate(this);
    },
    watch: {
      // mnow: function(newVal,oldVal) {
      //   if(newVal!== oldVal) {
      //   }
      // }
    }
  }
</script>
<style lang="scss">
  #dateContainer {
    cursor: pointer;
    width: 100%;
    font-size: .95rem;
    .nowTime {
      display: flex;
      justify-content: space-between;
      height: 30px;
      line-height: 30px;
      i{
        color: rgb(16, 173, 235);
        font-size: 1.5rem;
      }
      .thisMon {
        height: 30px;
        line-height: 30px;
        font-weight: bold;
      }
    }
    table {
      font-size: .95rem;
      width: 100%;
      border: 1px solid #dfdfdf;
      tr {
        width: 100%;
        border: 1px solid #f6f6f6;
        border-right: none;
        border-left: none;
        display: flex;
        justify-content: space-around;
        td {
          flex: 1;
          border-right: 1px solid #f6f6f6;
          text-align: center;
          height: 48px;
          line-height: 48px;
          font-weight: bold;
        }
        tr:last-child {
          border: none;
        }
        td.thisDay {
          background-color: rgb(16, 173, 235);
          color: #ffffff;
        }
        .isRed {
          color: rgb(212, 44, 44);
        }
      }
      tr.xiqi {
        background-color: rgb(16, 173, 235);
        color: #fff;
        font-weight: bold;
        td {
          height: 26px;
          line-height: 26px;
        }
      }
    }
  }
</style>