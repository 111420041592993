<template>
    <div class="home">
        <el-card class="glo-mgb10 area-1">
            <div class="flex-center">
                <div>您好，欢迎使用单点登录管理平台！</div>
                <!-- <div class="welcome-info">
                    <span class="ip">本机IP：{{userAttr.loginIp}}</span>
                    <span>上次登录时间：{{userAttr.loginDate}}</span>
                </div> -->
            </div>
        </el-card>
        <el-card class="glo-mgb10 area-2">
            <div class="glo-title">快捷导航</div>
            <div class="inner">
                <el-row :gutter="30" type="flex" justify="center" align="middle" style="height:100%">
                    <el-col :span="6">
                        <div class="center-mgr">
                            <router-link to="/user">
                                <div class="block bg-1">
                                    <div>
                                        <div><i class="el-icon-menu icon"></i></div>
                                        <div class="name">用户管理</div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="center-mgr">
                            <router-link to="/role">
                                <div class="block bg-2">
                                    <div>
                                        <div><i class="el-icon-s-custom icon"></i></div>
                                        <div class="name">角色管理</div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="center-mgr">
                            <router-link to="/menu">
                                <div class="block bg-3">
                                    <div>
                                        <div><i class="el-icon-s-platform icon"></i></div>
                                        <div class="name">菜单管理</div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="center-mgr">
                            <router-link to="/log">
                                <div class="block bg-4">
                                    <div>
                                        <div><i class="el-icon-s-tools icon"></i></div>
                                        <div class="name">日志管理</div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-card>
        <el-row :gutter="10" class="area-3">
            <el-col :span="8" style="height:100%">
                <el-card class="block-heihgt">
                    <div class="glo-title">个人资料</div>
                    <div class="user-inner">
                        <div class="avatar"><el-avatar src="https://placekitten.com/80/80"></el-avatar></div>
                        <div class="glo-flex-justify-between f-row0">
                            <div class="f-label">登录姓名</div>
                            <div class="f-content">{{userAttr.userName || '暂无'}}</div>
                        </div>
                        <div class="glo-flex-justify-between f-row0">
                            <div class="f-label">登录账户</div>
                            <div class="f-content">{{userAttr.loginName || '暂无'}}</div>
                        </div>
                        <div class="glo-flex-justify-between f-row0">
                            <div class="f-label">电话</div>
                            <div class="f-content">{{userAttr.phone || '暂无'}}</div>
                        </div>
                        <div class="glo-flex-justify-between f-row0">
                            <div class="f-label">邮箱</div>
                            <div class="f-content">{{userAttr.email || '暂无'}}</div>
                        </div>
                        <div class="glo-flex-justify-between f-row0">
                            <div class="f-label">所属部门</div>
                            <div class="f-content">{{userAttr.organName || '暂无'}}</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="10" style="height:100%">
                <el-card class="block-heihgt">
                    <div class="glo-title">公告栏</div>
                    <div class="notice-inner glo-relative">
                        <div class="text-notice">
                            暂无公告信息
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6" style="height:100%">
                <el-card class="block-heihgt">
                    <div class="glo-title">简洁日历</div>
                    <div class="date-inner">
                        <Date />
                    </div>
                </el-card>
            </el-col>
        </el-row>
        
    </div>
</template>

<script>
import Date from '@/components/Date.vue'
export default {
    components:{
        Date
    },
    props:['userAttr'],
    data(){
        return{
            // userAttr:{}
        }
    },
    methods:{},
    mounted(){
    },
    // watch:{
    //     userAttr:function(value){
    //         console.log(value);
    //     }
    // }
}
</script>

<style lang="scss" scoped>
.home{
    width: calc(100% - 10px);
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .area-1{
        height: 14%;
    }
    .area-2{
        height: 30%;
    }
    .area-3{
        height: calc(56% - 30px);
        .block-heihgt{
            height: calc(100% - 10px);
        }
    }
    .flex-center{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }
    .welcome-info{
        font-size: .9rem;
        margin: 15px 0 10px 0;
        color: rgb(36, 173, 228);
        .ip{
            margin-right: 30px;
        }
    }
    .inner{
        text-align: center;
        height: 88%;
        .center-mgr{
            display: flex;
            justify-content: center;
        }
        .block{
            border-radius: 5px;
            height: 180px;
            width: 180px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .icon{
                font-size: 3rem;
                color: #ffffff;
            }
            .name{
                font-size: .95rem;
                margin-top: 4px;
                color: #ffffff;
                font-weight: bold;
            }
            &:hover{
                opacity: 0.8;
            }
            
        }
        .bg-1{
            background-color: #59d4d8;
        }
        .bg-2{
            background-color: #f8ba34;
        }
        .bg-3{
            background-color: #56c4e6;;
        }
        .bg-4{
            background-color: #62D482;
        }
        a{
            text-decoration: none;
        }
    }
    .user-inner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 90%;
        .avatar{
            text-align: center;
            margin: 25px 0;
            .el-avatar{
                width: 80px;
                height: 80px;
            }
        }
        .f-row0{
            padding: 14px 0;
            border-bottom: 1px solid #f0f0f0;
            font-size: .95rem;
            .f-label{
                color: #6d6d6d;
            }
            .f-content{
                color: #272727;
            }
        }
    }
    .date-inner{
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 90%;
    }
    .notice-inner{
        padding: 20px;
        .text-notice{
            height: 325px;
            font-size: .9rem;
        }
    }
}
</style>