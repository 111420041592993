<template>
    <div class="about">
        <el-card class="glo-mgb10">
            <div class="glo-title">使用说明</div>
            <div class="explain">
                <ol>
                    <li>本平台用于管理公司内部各软件终端登录账号权限</li>
                    <li>不可随意停用删除账户信息</li>
                    <li>除最高管理员外，不可修改他人权限信息</li>
                    <li>严格按照上级领导规定使用账号，不可随意更改账号密码信息</li>
                    <li>账号仅限个人操作，不可转借他人使用</li>
                </ol>
            </div>
        </el-card>
        <el-card class="glo-mgb10">
            <!-- <div class="glo-title">关于平台</div> -->
            <div class="info">
                <div class="copyright">Copyright © 2020-2022 北京新科汇智科技发展有限公司.</div>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    }
}
</script>

<style lang="scss" scoped>
.about{
    .explain{
        ol{
            li{
                font-size: .9rem;
                padding: 8px 0;
            }
        }
    }
    .info{
        .copyright{
            text-align: center;
            font-size: .95rem;
            color: rgb(121, 121, 121);
        }
    }
}
</style>