<template>
  <div class="role">
    <el-card class="condition">
      <el-row :gutter="20">
        <el-col :span="4" :xs="16" :sm="6" :lg="5" :xl="4"
          ><el-input
            placeholder="请输入路段名称"
            v-model="filtrate.roadName"
            size="small"
            clearable
          ></el-input
        ></el-col>
        <el-col :span="4" :xs="10" :sm="7" :lg="5" :xl="4">
          <el-button type="success" size="small" round @click="search"
            ><i class="el-icon-search glo-icon-mgr2"></i>搜索</el-button
          >
          <el-button type="warning" size="small" round @click="reset"
            ><i class="el-icon-refresh glo-icon-mgr2"></i>重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <el-card class="table-inner">
      <div class="handle-0">
        <el-button type="primary" size="small" @click="handleAdd"
          >创建路段</el-button
        >
      </div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        class="glo-table-switch"
        v-loading="loading"
        element-loading-text="加载中"
      >
        <el-table-column prop="roadName" label="路段名称"></el-table-column>
        <el-table-column
          prop="project"
          label="项目编号"
          :formatter="dataRule"
        ></el-table-column>
        <el-table-column prop="roadType" label="道路类型"></el-table-column>
        <el-table-column prop="comment" label="备注"></el-table-column>
        <el-table-column label="操作" min-width="100">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" type="primary" size="mini"
              >编辑</el-button
            >
            <el-button
              @click="handleDelete(scope.row.id)"
              type="danger"
              size="mini"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pages-0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pages.current"
          :page-sizes="pages.option"
          :page-size="pages.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pages.total"
        ></el-pagination>
      </div>
    </el-card>

    <el-dialog
      :title="dialogTitle === 1 ? '创建路段' : '编辑路段'"
      :visible.sync="dialogEdit"
      width="40%"
      @closed="dialogCloseEdit"
      :close-on-click-modal="false"
    >
      <el-form
        ref="projForm"
        :rules="rules"
        :model="projForm"
        label-width="80px"
      >
        <el-form-item label="路段名称" prop="roadName">
          <el-input
            v-model="projForm.roadName"
            maxlength="12"
            size="small"
            placeholder="请填写路段名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="道路类型">
          <el-select
            v-model="projForm.roadType"
            clearable
            placeholder="请选择道路类型"
            style="width:100%"
          >
            <el-option
              v-for="item in roadTypeOtp"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目编号" prop="project">
          <el-select
            filterable
            v-model="projForm.project"
            clearable
            placeholder="请选择项目名称"
            style="width:100%"
          >
            <el-option
              v-for="item in projectOptions"
              :key="item.id"
              :label="item.projectDetail"
              :value="item.projCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="comment">
          <el-input
            v-model="projForm.comment"
            maxlength="12"
            size="small"
            placeholder="请填写备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="submit" size="small">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { selectDictLabel } from "@/util/selectDictLabel";
export default {
  data() {
    return {
      loading: true,
      pages: {
        current: 1,
        option: [8, 10, 12, 20],
        size: 10,
        total: 0,
      },
      tableData: [],
      currentPage: 1,
      filtrate: {
        roadName: "",
        status: "",
      },
      projForm: {
        roadName: "",
      },
      rules: {
        roadName: [
          { required: true, message: "请填写路段名称", trigger: "blur" },
        ],
      },
      dialogTitle: 1,
      dialogEdit: false,
      dialogMenu: false,
      projectOptions: [],
      roadTypeOtp: [
        { label: "道路", value: "1" },
        { label: "公路", value: "2" },
      ],
    };
  },
  methods: {
    handleSizeChange(size) {
      this.pages.current = 1;
      this.pages.size = size;
      this.loading = true;
      this.loadData();
    },
    handleCurrentChange(page) {
      this.loading = true;
      setTimeout(() => {
        this.pages.current = page;
        this.loadData();
      }, 100);
    },
    // 筛选-检索
    search() {
      this.loading = true;
      this.pages.current = 1;
      setTimeout(() => {
        this.loadData();
      }, 500);
    },
    // 筛选-重置
    reset() {
      this.filtrate = {
        roadName: "",
      };
      this.search();
    },
    // 增加路段
    handleAdd() {
      this.dialogTitle = 1;
      this.dialogEdit = true;
    },
    // 编辑路段信息
    handleEdit(row) {
      this.dialogTitle = 2;
      this.dialogEdit = true;
      this.projForm = { ...row };
    },
    // 关闭编辑对话框
    dialogCloseEdit() {
      (this.projForm = {
        roadName: "",
      }),
        setTimeout(() => {
          this.$refs["projForm"].clearValidate();
        }, 10);
    },
    // 提交保存
    submit() {
      let data = { ...this.projForm };
      this.$refs["projForm"].validate((valid) => {
        if (valid) {
          if (this.dialogTitle === 1) {
            // 新增
            this.$api.roadSave(data).then((d) => {
              this.$message({
                type: "success",
                message: "创建成功!",
              });
              this.dialogEdit = false;
              this.loading = true;
              this.pages.current = 1;
              setTimeout(() => {
                this.loadData();
              }, 500);
            });
          } else if (this.dialogTitle === 2) {
            // 修改
            this.$api.roadUpdate(data).then((d) => {
              this.$message({
                type: "success",
                message: "更新成功!",
              });
              this.dialogEdit = false;
              this.loading = true;
              this.pages.current = 1;
              setTimeout(() => {
                this.loadData();
              }, 500);
            });
          }
        } else {
          return false;
        }
      });
    },
    // 删除用户
    handleDelete(id) {
      this.$confirm("确定要删除该路段吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.roadDel(id).then((d) => {
            this.$message({
              type: "success",
              message: "操作成功!",
              duration: 1500,
            });
            this.loading = true;
            this.pages.current = 1;
            setTimeout(() => {
              this.loadData();
            }, 500);
          });
        })
        .catch(() => {});
    },
    loadData() {
      let data = {
        roadName: this.filtrate.roadName,
      };
      if (!data.roadName) {
        delete data.roadName;
      }
      return new Promise((resolve, rejuect) => {
        this.$api
          .roadPage(this.pages.current, this.pages.size, data)
          .then((d) => {
            if (d?.records?.length) {
              this.tableData = d.records;
              this.pages.total = +d.total;
            } else {
              this.tableData = [];
            }
            this.loading = false;
            resolve();
          });
      });
    },

    //获取全部项目
    getProjectData() {
      this.$api.projectList({}).then((d) => {
        if (d) {
          let projData = d;
          projData.forEach((res) => {
            let projectArr = {};
            projectArr.projCode = res.projCode;
            projectArr.projName = res.projName;
            projectArr.projectDetail = res.projName + res.projDetail;
            this.projectOptions.push(projectArr);
          });
          setTimeout(() => {
            this.loadData();
          }, 300);
        }
      });
    },

    //项目编号转译
    dataRule: function(row, column) {
      switch (column.property) {
        case "project":
          return selectDictLabel(
            this.projectOptions,
            row.project,
            "projCode",
            "projectDetail"
          );
      }
    },
  },
  mounted() {
    // this.loadData();
    this.getProjectData();
  },
};
</script>

<style lang="scss" scoped>
.role {
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  .condition {
    margin-bottom: 10px;
    .el-col {
      display: inline-block;
      margin: 5px 0;
    }
  }
  .table-inner {
    .handle-0 {
      margin-bottom: 10px;
    }
    .pages-0 {
      margin-top: 10px;
      text-align: right;
    }
  }
  .menu-dialog {
    border: 1px solid #ccc;
    padding: 4px;
  }
  .mgb10 {
    margin-bottom: 10px;
  }
}
</style>
