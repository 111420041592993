<template>
    <div class="user">
        <el-card class="condition">
            <el-row :gutter="20">
                <el-col :span="4" :xs="16" :sm="6" :lg="5" :xl="4"><el-input placeholder="请输入用户账号" v-model="filtrate.userName" size="small" clearable></el-input></el-col>
                <el-col :span="4" :xs="10" :sm="6" :lg="5" :xl="4">
                    <el-button type="success" size="small" round @click="search"><i class="el-icon-search glo-icon-mgr2"></i>搜索</el-button>
                    <el-button type="warning" size="small" round @click="reset"><i class="el-icon-refresh glo-icon-mgr2"></i>重置</el-button>
                </el-col>
            </el-row>
        </el-card>
        <el-card class="table-inner">
            <el-table
                :data="tableData"
                border
                style="width: 100%"
                class="glo-table-switch"
                v-loading="loading"
                element-loading-text="加载中"
            >
                <el-table-column prop="userName" label="用户名" min-width="100"></el-table-column>
                <el-table-column prop="thirdUniqueAccount" label="微信账号" min-width="110"></el-table-column>
                <el-table-column prop="officialAccounts" label="公众号" min-width="110"></el-table-column>

                <el-table-column label="是否绑定系统用户" min-width="110">
                    <template slot-scope="scope">
                        {{scope.row.bindFlag == '0'?'未绑定':'已绑定'}}
                    </template>
                </el-table-column>
              <el-table-column prop="createTime" label="创建时间" min-width="110"></el-table-column>

            </el-table>
            <div class="pages-0">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pages.current"
                    :page-sizes="pages.option"
                    :page-size="pages.size"
                    layout="total, sizes, prev, pager, next, jumper" :total="pages.total"
                ></el-pagination>
            </div>
        </el-card>

    </div>
</template>

<script>
export default {
    data(){
        return{
            loading:true,
            // statusOpt: [
            //     { value: '1', label: '启用' },
            //     { value: '0',label: '停用'}
            // ],
            tableData: [],
            orgOpt:[],
            pages:{
                current:1,
                option:[8, 10, 12,20],
                size:10,
                total:0
            },
            filtrate:{
                loginName:'',
                organId:'',
                // status:'',
            },
            userForm:{
                userName:'',
                organId:'',
                loginName:'',
                loginPassword:'',
                phone:'',
                email:'',
                marketRole:1,
                roleIdList:[]
                // status:1
            },
            marketRoleOpt:[
                {value: 1,label: '市场人员'},
                {value: 0,label: '非市场人员'}
            ],
            userRoleOpt:[],
            rules:{
                userName: [
                    { required: true, message: '请填写姓名', trigger: 'blur' }
                ],
                organId: [
                    { required: true, message: '请选择上级机构', trigger: 'change' }
                ],
                roleIdList: [
                    { required: true, message: '请选择权限角色', trigger: 'change' }
                ],
                loginName: [
                    { required: true, message: '请填写账号', trigger: 'blur' },
                    { min: 2, max: 16, message: '长度在 2 到 16 个字符', trigger: 'blur' }
                ],
                loginPassword: [
                    { required: true, message: '请填写密码', trigger: 'blur' }
                ]
            },
            dialogTitle:1,
            dialog:false
        }
    },
    methods:{
        handleSizeChange(size) {
            this.pages.current = 1;
            this.pages.size = size;
            this.loading = true;
            this.loadData();
        },
        handleCurrentChange(page) {
            this.loading = true;
            setTimeout( ()=>{
                this.pages.current = page;
                this.loadData();
            },100)
        },
        // 筛选-检索
        search(){
            this.loading = true;
            this.pages.current = 1;
            setTimeout(()=>{
                this.loadData();
            },500)
        },
        // 筛选-重置
        reset(){
            this.filtrate={
                loginName:'',
                organId:'',
                // status:'',
            }
            this.search();
        },

        loadData(){
            let data = {
              userName:this.filtrate.userName
            }
            // debugger
            return new Promise( (resolve,rejuect) => {
                this.$api.wechatUserPage(this.pages.current,this.pages.size,data).then( d => {
                    if(d?.records?.length){
                        this.tableData = d.records;
                        this.pages.total = +d.total;
                    }else{
                        this.tableData = [];
                    }
                    this.loading = false;
                    resolve();
                })
            })
        }
    },
    mounted(){
        this.loadData();
    }
}
</script>

<style lang="scss" scoped>
.user{
    width: calc(100% - 10px);
    height: 100%;
    overflow-y: auto;
    .condition{
        margin-bottom: 10px;
        .el-col{
            display: inline-block;
            margin: 5px 0;
        }
    }
    .table-inner{
        .handle-0{
            margin-bottom: 10px;
        }
        .pages-0{
            margin-top: 10px;
            text-align: right;
        }
    }
}
</style>
