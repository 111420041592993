<template>
    <div class="role">
        <el-card class="condition">
            <el-row :gutter="20">
                <el-col :span="4" :xs="16" :sm="6" :lg="5" :xl="4"><el-input placeholder="请输入项目名称" v-model="filtrate.projName" size="small" clearable></el-input></el-col>
                <el-col :span="4" :xs="10" :sm="7" :lg="5" :xl="4">
                    <el-button type="success" size="small" round @click="search"><i class="el-icon-search glo-icon-mgr2"></i>搜索</el-button>
                    <el-button type="warning" size="small" round @click="reset"><i class="el-icon-refresh glo-icon-mgr2"></i>重置</el-button>
                </el-col>
            </el-row>
        </el-card>
        <el-card class="table-inner">
            <div class="handle-0">
                <el-button type="primary" size="small" @click="handleAdd">创建项目</el-button>
            </div>
            <el-table
                :data="tableData"
                border
                style="width: 100%"
                class="glo-table-switch"
                v-loading="loading"
                element-loading-text="加载中"
            >
<!--                <el-table-column prop="id" label="编号" min-width="80"></el-table-column>-->
                <el-table-column prop="projCode" label="项目编号" min-width="100"></el-table-column>
                <el-table-column prop="projName" label="项目名称" min-width="100"></el-table-column>
                <el-table-column prop="projDetail" label="项目描述" min-width="100"></el-table-column>
                <el-table-column prop="projPath" label="web端路径" min-width="100"></el-table-column>
                <el-table-column prop="appPath" label="移动端路径" min-width="100"></el-table-column>
                <el-table-column prop="projClient" label="客户" min-width="100"></el-table-column>
                <el-table-column prop="sort" label="排序" min-width="100"></el-table-column>
                <el-table-column prop="remark" label="备注" min-width="100"></el-table-column>
                <el-table-column label="操作" min-width="200">
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.row)" type="primary" size="mini">编辑</el-button>
                        <el-button @click="handleDelete(scope.row.id)" type="danger" size="mini">删除</el-button>
                        <el-button @click="handleData(scope.row)" type="success" size="mini">配置</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pages-0">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pages.current"
                    :page-sizes="pages.option"
                    :page-size="pages.size"
                    layout="total, sizes, prev, pager, next, jumper" :total="pages.total"
                ></el-pagination>
            </div>
        </el-card>

        <el-dialog
            :title="dialogTitle=== 1 ? '创建项目' : '编辑项目' "
            :visible.sync="dialogEdit"
            width="36%"
            @closed="dialogCloseEdit"
            :close-on-click-modal="false"
        >
            <el-form ref="projForm" :rules="rules" :model="projForm" label-width="80px">
                <el-form-item label="项目编号" prop="projCode">
                  <el-input v-model="projForm.projCode" maxlength="18" size="small" placeholder="请填写项目编号"></el-input>
                </el-form-item>
                <el-form-item label="项目名称" prop="projName">
                    <el-input v-model="projForm.projName" maxlength="18" size="small" placeholder="请填写项目名称"></el-input>
                </el-form-item>
                <el-form-item label="项目描述" prop="projDetail">
                  <el-input v-model="projForm.projDetail" maxlength="30" size="small" placeholder="请填写项目描述"></el-input>
                </el-form-item>
                <el-form-item label="客户" prop="projClient">
                  <el-input v-model="projForm.projClient" maxlength="20" size="small" placeholder="请填写客户"></el-input>
                </el-form-item>
                <el-form-item label="web端路径" prop="projPath">
                  <el-input v-model="projForm.projPath" maxlength="160" size="small" placeholder="请填写路径"></el-input>
                </el-form-item>
                <el-form-item label="移动端路径" prop="appPath">
                  <el-input v-model="projForm.appPath" maxlength="160" size="small" placeholder="请填写路径"></el-input>
                </el-form-item>
                <el-form-item label="排序" prop="sort">
                  <el-input-number size="mini" v-model="projForm.sort"></el-input-number>
                </el-form-item>
                <el-form-item label="描述">
                    <el-input v-model="projForm.remark" maxlength="120" type="textarea" :rows="4" size="small" placeholder="可填写项目描述"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-upload
                        class="upload-demo"
                        action="/auth/upload/uploadImages"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :before-remove="beforeRemove"
                        :before-upload="beforeImageUpload"
                        multiple
                        :limit="1"
                        :on-exceed="handleExceed"
                        :on-success="handleAvatarSuccess"
                        :file-list="fileList">
                        <el-button size="small" type="primary">点击上传项目图片</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传一张项目图片</div>
                        </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer">
                <el-button type="primary" @click="submit" size="small">提 交</el-button>
            </span>
        </el-dialog>
      <el-dialog
          title="项目配置"
          :visible.sync="dialogData"
          width="36%"
          @closed="dialogCloseData"
          :close-on-click-modal="false"
      >

        <div class="menu-dialog">
          <el-checkbox-group v-model="prodPropList" >
            <el-checkbox v-for="proj in productList" :label="proj.prodCode" :key="proj.prodCode">{{proj.prodName}}</el-checkbox>
          </el-checkbox-group>
        </div>
        <span slot="footer">
                  <el-button type="primary" @click="submitProjectList" size="small">提 交</el-button>
              </span>
      </el-dialog>
    </div>
</template>

<script>
export default {
    data(){
        return{
            loading:true,
            pages:{
                current:1,
                option:[8, 10, 12,20],
                size:10,
                total:0
            },
            tableData: [],
            currentPage:1,
            filtrate:{
                roleName:'',
                status:'',
            },
            projForm:{
                projName:'',
                projCode:'',
                projClient:'',
                projDetail:'',
                projPath:'',
                appPath:'',
                picUrl: '',
                // scope:[],
            },
            rules:{
              projName: [
                    { required: true, message: '请填写项目名称', trigger: 'blur' },
                ],
              projCode: [
                { required: true, message: '请填写项目编号', trigger: 'blur' },
              ],
              projClient: [
                { required: true, message: '请填写客户', trigger: 'blur' },
              ]
            },
            dialogTitle:1,
            dialogEdit:false,
            dialogMenu:false,
          prodPropList:[],
          productList:[],
          prodCodeList:[],
          dialogData:false,
          fileList:[]
        }
    },
    methods:{

        //图片上传方法
         handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        //限制上传图片个数
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        //移除当前图片
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${ file.name }？`);
        },
        //限制图片上传大小
        beforeImageUpload(rawFile) {
            if (rawFile.size / 1024 / 1024 > 1) {
                this.$message.error("图片大小不能超过 1MB!");
                return false;
            }
            return true;
        },
        //上传文件成功
        handleAvatarSuccess (response, file, fileList) {
            this.projForm.picUrl = file.response.msg;
        },

        handleSizeChange(size) {
            this.pages.current = 1;
            this.pages.size = size;
            this.loading = true;
            this.loadData();
        },
        handleCurrentChange(page) {
            this.loading = true;
            setTimeout( ()=>{
                this.pages.current = page;
                this.loadData();
            },100)
        },
        // 筛选-检索
        search(){
            this.loading = true;
            this.pages.current=1;
            setTimeout(()=>{
                this.loadData();
            },500)
        },
        // 筛选-重置
        reset(){
            this.filtrate={
                roleName:'',
                status:'',
                // status:1,
            }
            this.search();
        },
        // 提交项目分配权限
        submitProjectList(){
          this.$api.projectProduct(this.projForm.projCode,this.prodPropList).then(d => {
            this.dialogData = false;
            this.$message({
              type: 'success',
              message: '操作成功!',
              duration:1500
            });
            this.loading = true;
            this.pages.current = 1;
            setTimeout(()=>{
              this.loadData();
            },500)
          })
        },
        dialogCloseData(){
          this.prodPropList = [];
        },
        // 表格开关切换状态
        // changeSatus(row){
        //     console.log(row);
        //     let text = row.status === 1? '停用': '启用';
        //     this.$confirm(`确定要${text}该角色吗?`, '提示', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //         type: 'warning'
        //     }).then(() => {
        //         // this.tableData.forEach( e => {
        //         //     if(e.id === row.id){
        //         //         row.status === 1 ?
        //         //         e.status = 0 : e.status = 1
        //         //     }
        //         // })
        //         this.$message({
        //             type: 'success',
        //             message: '操作成功!',
        //             duration:1500
        //         })
        //     }).catch(() => {})
        // },
        // 增加用户
        handleAdd(){
            this.dialogTitle = 1;
            this.dialogEdit = true;
        },
        // 编辑用户信息
        handleEdit(row){
            this.dialogTitle = 2;
            this.dialogEdit = true;
            this.projForm = {...row}
        },
        //配置产品
        handleData(row){
          debugger
          this.dialogData = true;
          this.projForm.projCode = row.projCode;
          this.prodPropList = row.productList;
        },
        //获取产品信息
        productInfo(){
          return new Promise( (resolve,rejuect) => {
            this.$api.productList(0).then( d => {
              let me = this;
              this.productList  = d||[];
              this.productList.map(function (item){
                me.prodCodeList.push(item.prodCode);
              })
              this.loading = false;
              resolve();
            })
          })
        },
        // 关闭编辑对话框
        dialogCloseEdit(){
          this.projForm ={
                projName:'',
                projCode:'',
                projClient:'',
                projDetail:''
            // scope:[],
          },
            setTimeout(()=>{
                this.$refs['projForm'].clearValidate();
            },10)
        },
        // 关闭菜单分配对话框
        dialogCloseMenu(){
            this.$refs.tree.setCheckedKeys([]);
            this.menuPropList = [1];
        },
        // 提交保存
        submit(){
            let data = {...this.projForm};
            this.$refs['projForm'].validate((valid) => {
                if (valid) {
                    if(this.dialogTitle === 1){
                        // 新增
                        delete data.id;
                        this.$api.projectSave(data).then( d=> {
                            this.$message({
                                type: 'success',
                                message: '创建成功!'
                            });
                            this.dialogEdit = false;
                            this.loading = true;
                            this.pages.current = 1;
                            setTimeout(()=>{
                                this.loadData();
                            },500)
                        })
                    }else if(this.dialogTitle === 2){
                        // 修改
                        delete data.menuIdList;
                        this.$api.projectUpdate(data).then( d=> {
                            this.$message({
                                type: 'success',
                                message: '更新成功!'
                            });
                            this.dialogEdit = false;
                            this.loading = true;
                            this.pages.current = 1;
                            setTimeout(()=>{
                                this.loadData();
                            },500)
                        })
                    }

                } else {
                    return false;
                }
            });
        },
        // 删除用户
        handleDelete(id){
            this.$confirm('确定要删除该项目吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.projectDel(id).then( d =>{
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                        duration:1500
                    });
                    this.loading = true;
                    this.pages.current = 1;
                    setTimeout(()=>{
                        this.loadData();
                    },500)
                })
            }).catch(() => {})
        },
        // 遍历格式化树结构
        filterJson(arr) {
            const res = [];
            arr.forEach(v => {
                let tmp = { ...v };
                    tmp.label = v.name;
                    tmp.value = v.id;
                    if (tmp.children?.length>0) {
                        tmp.children = this.filterJson(tmp.children)
                    }else{
                        tmp.children = undefined;   // 修复-级联菜单最后一级暂无数据问题
                    }
                    res.push(tmp)
            })
            return res;
        },
        loadData(){
            let data = {
              projName:this.filtrate.projName
            }
            return new Promise( (resolve,rejuect) => {
                this.$api.projectPage(this.pages.current,this.pages.size,data).then( d => {
                    if(d?.records?.length){
                        this.tableData = d.records;
                        this.pages.total = +d.total;
                    }else{
                        this.tableData = [];
                    }
                    this.loading = false;
                    resolve();
                })
            })
        }
    },
    mounted(){
        this.productInfo();
        this.loadData();

    }
}
</script>

<style lang="scss" scoped>
.role{
    width: calc(100% - 10px);
    height: 100%;
    overflow-y: auto;
    .condition{
        margin-bottom: 10px;
        .el-col{
            display: inline-block;
            margin: 5px 0;
        }
    }
    .table-inner{
        .handle-0{
            margin-bottom: 10px;
        }
        .pages-0{
            margin-top: 10px;
            text-align: right;
        }
    }
    .menu-dialog{
        border: 1px solid #ccc;
        padding: 4px;
    }
    .mgb10{
        margin-bottom: 10px;
    }
}
</style>
